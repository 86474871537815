import { getToken, setToken } from '@/utils/storage'

export default {
  namespaced: true,
  state() {
    return {
      token: '',
      loginStatus: localStorage.getItem('loginStatus')||false
    }
  },
  mutations: {
    setUserToken(state, payload) {
      state.token = payload
      console.log(payload,8888);
      // localStorage.setItem('session_id',payload)
      // setToken(payload)
    },
    setLoginStatus(state, payload) {
      state.loginStatus = payload
      localStorage.setItem('loginStatus', payload)
    }
  },
  actions :{
    UserToken(ctx,session_id){
      ctx.commit('setUserToken',session_id)
      localStorage.setItem('session_id',session_id)

    }
  }
}
