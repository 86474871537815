import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '@/views/index'

// import Layout from '@/views/layout'
// import Login from '@/views/login'
// import Dashboard from '@/views/dashboard'
// import Article from '@/views/article'

Vue.use(VueRouter)

const routes = [
  // { path: '/login', component: Login },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [
  //     { path: 'dashboard', component: Dashboard },
  //     { path: 'article', component: Article }
  //   ]
  // }
  {
    path: '/',
    name:'index',
    component: index,
    // redirect: '/index',
    // children: [
    //   { path: 'index', component: index },

    //   // { path: 'dashboard', component: Dashboard },
    //   // { path: 'article', component: Article }
    // ]
  }
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
