// WebSocketClient.js

const WebSocketClient = {
  socket: null,
  connected: false,
  authResult: null,
  subscribeResult: null,
  receivedData: [],
  secretKey: 'BVnSu87qd-GuLl1b905Cc', // 替换成你的秘钥

  init() {
    this.socket = new WebSocket('wss://open-api-ws.jin10.com/quotes');

    this.socket.onopen = () => {
      this.connected = true;
      // 发送鉴权请求
      this.sendAuthRequest();
    };

    this.socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.type === 'auth_result') {
        this.authResult = response.data.message;
        // 鉴权成功后发送订阅请求
        this.sendSubscribeRequest();
      } else if (response.type === 'subscribe_result') {
        this.subscribeResult = response.data.message;
      } else if (response.type === 'data') {
        // 处理接收到的数据
        this.handleReceivedData(response.data);
      }
    };

    this.socket.onclose = () => {
      // this.connected = false;
      console.log('WebSocket connection closed');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  },

  sendAuthRequest() {
    const authRequest = {
      action: 'auth',
      params: {
        'secret-key': this.secretKey
      }
    };
    this.socket.send(JSON.stringify(authRequest));
  },

  sendSubscribeRequest() {
    const subscribeRequest = {
      action: 'subscribe',
      params: {
        category: [
          'GOODS.*'
        ]
      }
    };
    this.socket.send(JSON.stringify(subscribeRequest));
  },

  handleReceivedData(data) {
    // console.log(data);
    // 处理接收到的数据，并更新数据变量
    this.receivedData.push(data); // 将收到的数据添加到 receivedData 数组中
  },

  close() {
    this.socket.close();
  }
};

// 导出 WebSocketClient 对象
export default WebSocketClient;
