<template>
  <div>
    <el-container v-loading.fullscreen.lock="fullscreenLoading">
      <el-header class="header">
        <div class="hd-left">
          <div class="name">{{ name }}</div>
          <div
            @click="leftShow"
            class="period"
            :class="leftshow ? 'selected' : ''"
          >
            {{ lang.Productlist || '产品列表' }}
          </div>
          <div class="mx-2"></div>
          <div
            @click="rightShow"
            class="period"
            :class="rightshow ? 'selected' : ''"
          >
            {{ lang.Placeorder || '下单' }}
          </div>
          <div class="mx-2"></div>

          <div style="display: flex; align-items: center">
            <div
              class="klinecharts-pro-period-bar"
              v-for="(item, index) in statusText"
              :key="index"
            >
              <div
                class="period"
                :class="timeflag == item.text ? 'selected' : ''"
                @click="changeTopTime(item.text, item.data)"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
          <!-- 指标 -->
          <!-- <div class="mx-2" style="margin-left: 8px"></div>
          <div class="tools">
            <span>{{ lang.index || '指标' }}</span>
          </div>-->
          <div class="mx-2"></div>
          <div class="tools" @click="setVisible = true">
            <img src="../../assets/image/set.png" alt />
          </div>
          <div class="mx-2"></div>
          <div class="icon" @click="generateThumbnail">
            <img src="../../assets/image/photo.png" alt />
          </div>
          <div class="mx-2"></div>
          <div class="icon" @click="zoomIn">
            <img src="../../assets/image/large.png" alt />
          </div>
          <div class="icon" @click="zoomOut">
            <img src="../../assets/image/samll.png" alt />
          </div>
          <div class="icon" @click="moveLeft">
            <img src="../../assets/image/left.png" alt />
          </div>
          <div class="icon" @click="moveRight">
            <img src="../../assets/image/right.png" alt />
          </div>
          <div class="icon" @click="handleFullScreen">
            <img src="../../assets/image/screenfull.png" alt />
          </div>
        </div>
        <div class="hd-right">
          <el-select
            size="small"
            v-model="value"
            :placeholder="lang.Pleaseselect || '请选择'"
            style="width: 146px; display: flex; align-items: center"
            @change="change"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <template>
                <div style="display: flex; align-items: center">
                  <img
                    :src="item.guoqi_pic"
                    alt
                    style="width: 36px; height: 26px; margin-right: 10px"
                  />
                  {{ item.name }}
                </div>
              </template>
            </el-option>
            <!-- <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <template >
                <div style="display: flex;align-items: center;"><img src="../../assets/image/photo.png" alt="" style="width: 26px;height:26px;margin-right:10px;">{{ item.label }}</div>
                </template>
            </el-option>-->
          </el-select>
          <div
            v-if="loginzt == 1&&emailname!==''"
            @click="openUserinfo"
            style="margin-left: 20px"
          >
            {{ emailname }}
          </div>
          <div v-else class="log-reg">
            <div @click="opneLogin" class="button">
              {{ lang.login || '登录' }}
            </div>
            <span>|</span>
            <div @click="opneRegister" class="button">
              {{ lang.register || '注册' }}
            </div>
          </div>
        </div>
      </el-header>

      <el-main class="main">
        <div class="sidebar">
          <div class="item">
            <span
              ><img src="../../assets/image/zxd.svg" alt="" class="img" />
              <div class="arrow" @click="showul(0)">
                <img
                  src="../../assets/image/arrow.svg"
                  class="img-responsive"
                  alt="Image"
                />
              </div>
            </span>
            <!-- <div class="arrow">
              <img src="../../assets/image/arrow.svg" class="img-responsive" alt="Image">
            </div> -->
            <ul v-if="show === 0">
              <li>水平直线</li>
              <li>水平射线</li>
              <li>水平线段</li>
              <li>垂直直线</li>
              <li>垂直射线</li>
              <li>垂直线段</li>
              <li>直线</li>
              <li>射线</li>
              <li>线段</li>
              <li>箭头</li>
              <li>价格线</li>
            </ul>
          </div>
          <div class="item">
            <span
              ><img src="../../assets/image/linechart.svg" alt="" class="img" />
              <div class="arrow" @click="showul(1)">
                <img
                  src="../../assets/image/arrow.svg"
                  class="img-responsive"
                  alt="Image"
                />
              </div>
            </span>
            <ul v-if="show == 1">
              <li>价格通道线</li>
              <li>平行直线</li>
            </ul>
          </div>
          <div class="item">
            <span
              ><img src="../../assets/image/yx.svg" alt="" class="img" />
              <div class="arrow" @click="showul(2)">
                <img
                  src="../../assets/image/arrow.svg"
                  class="img-responsive"
                  alt="Image"
                />
              </div>
            </span>
            <ul v-if="show == 2">
              <li @click="circle" >圆</li>
              <li @click="rect">矩形</li>
              <li>平行四边形</li>
              <li>三角形</li>
            </ul>
          </div>
          <div class="item">
            <span
              ><img src="../../assets/image/dx.svg" alt="" class="img" />
              <div class="arrow" @click="showul(3)">
                <img
                  src="../../assets/image/arrow.svg"
                  class="img-responsive"
                  alt="Image"
                />
              </div>
            </span>
            <ul v-if="show == 3">
              <li>斐波那契回调直线</li>
              <li>斐波那契回调线段</li>
              <li>斐波那契圆环</li>
              <li>斐波那契螺旋</li>
              <li>斐波那契速度阻力扇</li>
              <li>斐波那契趋势拓展</li>
              <li>江恩箱</li>
            </ul>
          </div>
          <div class="item">
            <span
              ><img src="../../assets/image/dbx.svg" alt="" class="img" />
              <div class="arrow" @click="showul(4)">
                <img
                  src="../../assets/image/arrow.svg"
                  class="img-responsive"
                  alt="Image"
                />
              </div>
            </span>
            <ul v-if="show == 4">
              <li>XABCD形态</li>
              <li>ABCD形态</li>
              <li>三浪</li>
              <li>五浪</li>
              <li>八浪</li>
              <li>任意浪</li>
            </ul>
          </div>
          <div class="item" style="border-bottom: 1px solid #ebedf1">
            <span
              ><img src="../../assets/image/ct.svg" alt="" class="img" />
              <div class="arrow" @click="showul(5)">
                <img
                  src="../../assets/image/arrow.svg"
                  class="img-responsive"
                  alt="Image"
                />
              </div>
            </span>
            <ul v-if="show == 5">
              <li>弱磁模式</li>
              <li>强磁模式</li>
            </ul>
          </div>
          <div class="item">
            <span
              ><img src="../../assets/image/suo.svg" alt="" class="img" />
            </span>
          </div>
          <div class="item" style="border-bottom: 1px solid #ebedf1">
            <span
              ><img src="../../assets/image/eyes_show.svg" alt="" class="img" />
            </span>
          </div>
          <div class="item">
            <span
              ><img src="../../assets/image/ljt.svg" alt="" class="img" />
            </span>
          </div>
        </div>
        <div class="left" v-if="leftshow">
          <div class="hd">
            <div class="search">
              <i
                class="el-icon-search"
                style="margin-left: 16px; margin-right: 10px; font-size: 20px"
              ></i>
              <!-- <el-input v-model="input" placeholder="请输入内容" style="
    background-color: transparent;
              color: #333;padding:20px;"></el-input>-->
              <input @input="Search" v-model="keyword" />
            </div>
            <el-select
              @change="change1"
              size="small"
              v-model="value1"
              :placeholder="lang.Pleaseselect || '请选择'"
              style="
                width: 146px;
                display: flex;
                align-items: center;
                border-radius: 20px;
                background-color: #f8f8f8;
                color: #333;
              "
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div
            v-if="currentIndex == -1"
            class="left-content"
            id="left-content"
            style="height: 700px"
          >
            <div
              class="item"
              v-for="(item, index) in currency[0]"
              :key="index"
              :data-item-id="item.id"
              :data-item-status="item.status"
              @click="currentData(item.id)"
              v-if="price[item.id]"
            >
            <!-- v-if="price[item.id]" -->
              <div class="item-name">
                <!-- <img class="img" :src="item.logo" style="width: 36px; height: 36px" /> -->
                <span style="padding-left: 10px">{{ item.name }}</span>
              </div>
              <div
                v-if="price != ''"
                class="price"
                :style="{
                  color: price[item.id].changeRate >= 0 ? '#0166fc' : '#f23c48',
                }"
              >
                <!-- {{ price[item.id].price }} -->
                {{ price ? price[item.id].price : item.price }}
              </div>
              <div
                v-if="price != '' && item.status != 5"
                class="changeRate"
                :style="{
                  'background-color':
                    price[item.id].changeRate >= 0 ? '#0166fc' : '#f23c48',
                }"
              >
                {{ price ? price[item.id].changeRate : item.changeRate }}%
              </div>
              <div
                style="
                  width: 80px;
                  text-align: center;
                  padding: 6px 0px;
                  background-color: #c1c1c1;
                  border-radius: 8px;
                  font-size: 12px;
                  color: #fff;
                "
                v-else
              >
                已休市
              </div>
              <!-- <div
                v-else
                :style="{
                  'color': item.changeRate >= 0 ? '#0166fc' : '#f23c48'
                }"
              >{{ item.changeRate }}%</div>-->
              <!-- <div
                v-if="price != ''"
                class="changeRate"
                :style="{
                  'background-color':
                    price[item.id].changeRate >= 0 ? '#0166fc' : '#f23c48'
                }"
              >{{ price[item.id].changeRate }}%</div>
              <div
                v-else
                :style="{
                  'color': item.changeRate >= 0 ? '#0166fc' : '#f23c48'
                }"
              >{{ item.changeRate }}%</div>-->
            </div>
          </div>

          <!-- 9999 -->
          <div
            v-else-if="currentIndex == 20"
            class="left-content"
            id="left-content"
            style="height: 700px"
          >
            <div
              class="item"
              v-for="(item, index) in currency"
              :key="index"
              :data-item-id="item.id"
              @click="currentData(item.id)"
              v-if="price[item.id]"
            >
              <div class="item-name">
                <!-- <img class="img" :src="item.logo" style="width: 36px; height: 36px" /> -->
                <span style="padding-left: 10px">{{ item.name }}</span>
              </div>
              <div
                class="price"
                :style="{
                  color: price[item.id].changeRate >= 0 ? '#0166fc' : '#f23c48',
                }"
              >
                {{ price[item.id].price }}
              </div>
              <div
                class="changeRate"
                :style="{
                  'background-color':
                    price[item.id].changeRate >= 0 ? '#0166fc' : '#f23c48',
                }"
              >
                {{ price[item.id].changeRate }}%
              </div>
            </div>
          </div>

          <div
            v-else-if="currentIndex === '' || currency === ''"
            class="left-content"
            id="left-content"
            style="height: 700px"
          >
            <el-empty :description="lang.Nodata || '暂无数据'"></el-empty>
          </div>

          <div v-else class="left-content" style="height: 700px">
            <div
              class="item"
              v-for="(item, index) in currency_type[0][value1].usdt_list"
              :key="index"
              :data-item-id="item.id"
              @click="currentData(item.id)"
            >
              <div class="item-name">
                <!-- <img class="img" :src="item.logo" style="width: 36px; height: 36px" /> -->
                <span style="padding-left: 10px">{{ item.name }}</span>
              </div>
              <div
                class="price"
                :style="{
                  color: price[item.id].changeRate >= 0 ? '#0166fc' : '#f23c48',
                }"
              >
                {{ price[item.id].price }}
              </div>
              <div
                class="changeRate"
                :style="{
                  'background-color':
                    price[item.id].changeRate >= 0 ? '#0166fc' : '#f23c48',
                }"
              >
                {{ price[item.id].changeRate }}%
              </div>
            </div>
          </div>
        </div>

        <div class="charts" v-loading="chartsLoading" ref="chartContainer">
          <div class="charts-hd">
            <div>{{ currentname }}</div>
            <!-- <div class="current">
              <div class="current-item">
                {{ lang.time || '时间' }}:
                <span>{{ currentChartsData[0] }}</span>
              </div>
              <div class="current-item">
                {{ lang.open || '开' }}:
                <span>{{ currentChartsData[1] }}</span>
              </div>
              <div class="current-item">
                {{ lang.high || '高' }}:
                <span>{{ currentChartsData[4] }}</span>
              </div>
              <div class="current-item">
                {{ lang.low || '低' }}:
                <span>{{ currentChartsData[3] }}</span>
              </div>
              <div class="current-item">
                {{ lang.closed || '收' }}:
                <span>{{ currentChartsData[2] }}</span>
              </div>
            </div>-->
          </div>
          <div
            class="echart"
            id="mychart"
            style="width: 100%; height: 100%"
          ></div>
        </div>

        <div class="right" v-if="rightshow">
          <div class="right-top">
            <div class="userId" v-if="loginzt == 1">
              idcode
              <span style="padding: 0 2px">:</span>
              {{ idcode }}
            </div>
            <div class="balance">{{ lang.Availablebalance || '可用余额' }}</div>
            <div class="money">{{ available_balance }}</div>
          </div>
          <div class="right-bottom">
            <div style="font-size: 14px">{{ currentname }}</div>
            <div style="color: #0166fc; font-size: 20px">
              {{ currentprice }}
            </div>

            <div style="width: 100%; margin-top: 2px">
              <el-select
                @change="change2"
                size="small"
                v-model="value2"
                :placeholder="lang.Pleaseselect || '请选择'"
                style="width: 100%"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="lang[item.label]"
                  :value="item.label"
                ></el-option>
                <!-- :value="item.value" -->
              </el-select>
            </div>
            <!-- 价格 -->
            <block v-if="value2 == 'Pendingorder'">
              <div style="font-size: 14px; margin-top: 10px">
                {{ lang.Price || '价格' }}
              </div>
              <div style="width: 100%; margin-top: 2px">
                <el-input
                  v-model="inputprice"
                  :placeholder="lang.enterprice || '请输入价格'"
                  style="
                    width: 100%;
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    background-color: #f8f8f8;
                    color: #333;
                    height: 32px;
                  "
                ></el-input>
              </div>
            </block>

            <!-- 倍数 -->
            <div style="font-size: 14px; margin-top: 10px">
              {{ lang.multiple || '倍数1' }}
            </div>
            <div style="width: 100%; margin-top: 2px">
              <el-select
                @change="change3"
                size="small"
                v-model="value3"
                :placeholder="lang.Pleaseselect || '请选择'"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  border-radius: 20px;
                  background-color: #f8f8f8;
                  color: #333;
                "
              >
                <el-option
                  v-for="item in options3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- 止损 -->
            <div>
              <div
                style="
                  margin-top: 10px;
                  margin-bottom: 4px;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div>{{ lang.Stoploss || '止损' }}</div>
                <div>
                  <el-switch
                    @change="stopLoss"
                    v-model="zhisunvalue"
                    active-color="#409eff"
                    inactive-color="#dcdfe6"
                  ></el-switch>
                </div>
              </div>
              <el-input-number
                style="width: 100%; margin-top: 2px"
                v-model="num"
                @change="handleChange"
                :min="0"
                label="止损"
                :disabled="!zhisunvalue"
              ></el-input-number>
            </div>

            <!-- 止盈 -->
            <div>
              <div
                style="
                  margin-top: 10px;
                  margin-bottom: 4px;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div>{{ lang.Stopprofit || '止盈' }}</div>
                <div>
                  <el-switch
                    @change="stopsurplus"
                    v-model="zhiyingvalue"
                    active-color="#409eff"
                    inactive-color="#dcdfe6"
                  ></el-switch>
                </div>
              </div>
              <el-input-number
                style="width: 100%; margin-top: 2px"
                v-model="num1"
                @change="handleChange1"
                :min="0"
                label="止盈"
                :disabled="!zhiyingvalue"
              ></el-input-number>
            </div>

            <!-- 买入数量 -->
            <div>
              <div
                style="
                  margin-top: 10px;
                  margin-bottom: 4px;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div>
                  {{ lang.Buyingquantity || '買入數量' }}(Step:{{ step }})
                </div>
              </div>
              <el-input-number
                style="width: 100%; margin-top: 2px"
                v-model="num2"
                :step="step"
                @change="handleChange2"
                :min="minnum"
                :max="maxnum"
                label="买入数量"
              ></el-input-number>
            </div>
            <!-- //价格计算 -->
            <div style="font-size: 12px; padding: 16px 0">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 8px;
                "
              >
                <div>{{ lang.Perlot || '每手' }}</div>
                <div>{{ lang.hand }}={{ meishou_number }}</div>
              </div>

              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 8px;
                "
              >
                <div>{{ lang.Estimatedcommission || '预估手续费' }}</div>
                <div>{{ sxf1 }}</div>
              </div>

              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 8px;
                "
              >
                <div>{{ lang.Estimatedmargin || '预估保证金' }}</div>
                <div>{{ computedprice }}</div>
              </div>
            </div>

            <!-- 买入卖出 -->
            <div style="display: flex">
              <el-button
                @click="deal(1)"
                style="
                  flex: 1 1 0%;
                  height: 36px;
                  background-color: #0166fc;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                type="primary"
                round
              >
                {{ lang.Buy || '买入' }}
              </el-button>
              <el-button
                @click="deal(2)"
                style="
                  flex: 1 1 0%;
                  height: 36px;
                  background-color: #f23c48;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                type="danger"
                round
              >
                {{ lang.sell || '卖出' }}
              </el-button>
            </div>
          </div>
        </div>
      </el-main>
      <!-- loginzt ==1 -->
      <!-- <el-footer v-if="loginStatus" class="footer"> -->
      <el-footer v-if="loginzt == 1&&emailname!==''" class="footer">
        <div class="footer-container">
          <div class="container-hd">
            <div class="tabs">
              <div
                class="tabs-item"
                v-for="(item, index) in ordertabs"
                :key="item + index"
                :class="active == index ? 'selected' : ''"
                :style="index == 0 ? 'padding-left:0;' : ''"
                @click="changeTabs(index)"
              >
                {{ lang[item] }}
              </div>
            </div>
            <div class="hd-right">
              <div class="yk">
                {{ lang.Profitloss || '盈亏' }}:
                <span style="color: #0199fd">{{ orderYk.yingkui }}</span>
              </div>
              <div class="bzj">
                {{ lang.Currentmargin || '当前保证金' }}:
                <span style="color: #000">{{ orderYk.margin }}</span>
              </div>
              <div class="fxl">
                {{ lang.Riskratio || '风险率' }}:
                <span style="color: #000">{{ orderYk.riskRate }}</span>
                %
              </div>
            </div>
          </div>
          <div class="content">
            <el-table
              v-loading="loading"
              :data="tableData"
              style="width: 100%; background-color: #fafbfe"
              :empty-text="lang.Nodata||'暂无数据'"
            >
              <el-table-column
                align="center"
                prop="currname"
                :label="lang.Tradingpair || '交易对'"
                
              ></el-table-column>
              <!-- <el-table-column align="center" prop="id"   :label="lang.Ordernumber || '订单号'"
                ></el-table-column> -->
              <el-table-column
                align="center"
                prop="id"
                :label="lang.Ordernumber || '订单号'"
                
              >
                <template slot-scope="scope">
                  <span :data-item-id="scope.row.id">{{ scope.row.id }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="lang.direction || '方向'"
                
              >
                <template slot-scope="scope">
                  <div
                    style="padding: 5px 0; color: #fff; border-radius: 80px"
                    :style="{
                      'background-color':
                        scope.row.type === 1 ? '#0166fc' : '#f23c48',
                    }"
                  >
                    {{
                      scope.row.type == 1
                        ? lang.Buy || '买入'
                        : lang.sell || '卖出'
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="number"
                :label="lang.hand || '手数'"
                width="120"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="price"
                :label="lang.Orderprice || '下单价'"
                
              ></el-table-column>
              <el-table-column
                v-if="active == 2"
                align="center"
                prop="current_price"
                :label="lang.Closingprice || '平仓价'"
                
              ></el-table-column>
              <el-table-column
                v-else
                align="center"
                prop="current_price"
                :label="lang.Currentprice || '当前价'"
                
              ></el-table-column>
              <el-table-column
                align="center"
                prop="stopsurplus_price"
                :label="lang.Stopprofit || '止盈'"
                
              ></el-table-column>
              <el-table-column
                align="center"
                prop="stoploss_price"
                :label="lang.Stoploss || '止损'"
                
              ></el-table-column>
              <el-table-column
                align="center"
                prop="commission"
                :label="lang.Fraistraitement || '手续费'"
                
              ></el-table-column>
              <el-table-column
                align="center"
                prop="margin"
                :label="lang.Securitydeposit || '保证金'"
                
              ></el-table-column>
              <el-table-column
                align="center"
                prop="yingkui"
                :label="lang.Profit || '收益'"
                
              >
                <template slot-scope="scope">
                  <div
                    :style="{
                      color: scope.row.yingkui > 0 ? '#0166fc' : '#f23c48',
                    }"
                  >
                    {{ scope.row.yingkui }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="lang.Openingtime || '开仓时间'"
                
              >
                <template slot-scope="scope">
                  {{ scope.row.createtime }}
                </template>
              </el-table-column>
              <el-table-column
                v-if="active != 2"
                align="center"
                width="200"
                :label="lang.Operation || '操作'"
              
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.status == 2"
                    @click="openOrder(scope.row)"
                    type="primary"
                    plain
                    round
                    style="color: #0166fc; padding: 6px 16px"
                  >
                    TP/SL
                  </el-button>

                  <el-button
                    v-if="scope.row.status == 2"
                    @click="opnepc(scope.row.id, 0)"
                    type="danger"
                    plain
                    round
                    style="color: #f23c48; padding: 6px 16px"
                  >
                    平仓
                  </el-button>
                  <el-button
                    v-if="scope.row.status == 1"
                    @click="opnepc(scope.row.id, 1)"
                    type="primary"
                    plain
                    round
                    style="color: #0166fc; padding: 6px 16px"
                  >
                    撤单
                  </el-button>
                  <!-- <span class="button" style="margin-right: 10px;background-color: #e8f4ff;color:#0166fc;">TP/SL</span>
                  <span class="button">平仓</span>-->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-footer>
    </el-container>
    <!-- 登录弹窗 -->
    <el-dialog class="login" :visible.sync="loginVisible" width="480px" center>
      <div
        slot="title"
        style="font-weight: 700; font-size: 20px; color: #d0d0d0"
      >
        {{ lang.login || '登录' }}
      </div>
      <div class="email">
        <div class="name" style="color: #0166fc">
          {{ lang.Emailaccount || '邮箱账号' }}
        </div>
        <input
          v-model="emailAccount"
          type="email"
          :placeholder="lang.enterEmail || '请输入邮箱'"
        />
      </div>
      <div class="email pwd">
        <div class="name">{{ lang.password || '密码' }}</div>
        <input
          v-model="password"
          type="password"
          :placeholder="lang.enterpassword || '请输入密码'"
        />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-top: 22px;
          font-size: 12px;
        "
      >
        <div>
          {{ lang.Newuser || '新用户1' }}?
          <span @click="goReg" style="color: #0199fd; cursor: pointer">
            {{ lang.JoinNow }}
          </span>
        </div>
        <!-- <div style="color: #cbcbcb">
          {{ lang.ForgotPassword || '忘记密码' }}
        </div>-->
      </div>

      <div class="button" @click="login">{{ lang.login || '登录' }}</div>
      <div
        @click="loginVisible = false"
        class="button"
        style="background-color: #eee; color: #a9a9b8"
      >
        {{ lang.Nologin || '暂不登录' }}
      </div>
    </el-dialog>

    <!-- 注册弹窗 -->
    <el-dialog class="login" :visible.sync="regVisible" width="480px" center>
      <div
        slot="title"
        style="font-weight: 700; font-size: 20px; color: #d0d0d0"
      >
        {{ lang.register || '注册' }}
      </div>
      <div class="email">
        <div class="name" style="color: #0166fc">
          {{ lang.Emailaccount || '邮箱账号' }}
        </div>
        <input
          v-model="emailAccount"
          type="email"
          :placeholder="lang.enterEmail || '请输入邮箱'"
        />
      </div>
      <div class="email pwd">
        <div class="name">{{ lang.password || '密码' }}</div>
        <input
          v-model="password"
          type="password"
          :placeholder="lang.enterpassword || '请输入密码'"
        />
      </div>
      <div class="email pwd">
        <div class="name">{{ lang.Enterpassword || '再次输入密码' }}</div>
        <input
          v-model="pwdAgin"
          type="password"
          :placeholder="lang.enterpassword || '请输入密码'"
        />
      </div>
      <div class="email pwd">
        <div class="name">{{ lang.yqm || '邀请码' }}</div>
        <input
          v-model="yqm"
          type="number"
          :placeholder="lang.EnterInvitationCode || '请输入邀请码'"
        />
      </div>

      <div @click="register" class="button">{{ lang.register || '注册' }}</div>
    </el-dialog>

    <!-- 订单修改弹窗 -->
    <el-dialog
      class="order"
      :visible.sync="orderVisible"
      width="480px"
      @close="handleClose"
    >
      <div slot="title" style="font-size: 20px">TP/SL</div>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="margin-left: -20px">
          {{ lang.Stopprofit || '止盈' }}
          <!-- @change="orderZy" -->
          <el-input-number
            v-model="orderzy"
            :min="0"
            label="订单止盈"
            style="margin-left: 10px; width: 220px"
          ></el-input-number>
        </div>
        <div style="margin-left: -20px; margin-top: 20px">
          {{ lang.Stoploss || '止损' }}
          <!-- @change="orderZs" -->
          <el-input-number
            v-model="orderzs"
            :min="0"
            label="订单止盈"
            style="margin-left: 10px; width: 220px"
          ></el-input-number>
        </div>
        <div @click="orderEdit" class="orderedit">
          {{ lang.edit + ' ' + 'TP/SL' || '修改' }}
        </div>
      </div>
    </el-dialog>
    <!-- 平仓弹框 -->
    <!-- :before-close="handleClose" -->
    <el-dialog
      :title="lang.Promptmessage || '提示'"
      :visible.sync="pcVisible"
      width="430px"
      class="pc"
    >
      <div v-if="pcorco == 0">{{ lang.yCloseposition || '是否平仓' }}</div>
      <div v-if="pcorco == 1">{{ lang.Cancelorder || '撤单' }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button style="padding: 8px 20px" @click="pcVisible = false">
          {{ lang.cancel || '取消' }}
        </el-button>
        <el-button
          style="padding: 8px 20px"
          type="primary"
          @click="pcorco == 0 ? pingCang() : cancelOrder()"
        >
          {{ lang.verify || '确认' }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="lang.screenshot || '截图'"
      :visible.sync="imgVisible"
      width="32%"
      class="pc"
    >
      <div
        slot="title"
        style="
          font-size: 20px;
          border-bottom: 1px solid #000;
          padding-bottom: 10px;
        "
      >
        {{ lang.screenshot }}
      </div>
      <div>
        <img :src="thumbnailDataURL" alt />
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button style="padding: 8px 20px;" @click="pcVisible = false">{{lang.cancel||'取消'}}</el-button> -->
        <el-button
          style="padding: 8px 20px"
          type="primary"
          @click="downloadChartImage"
        >
          {{ lang.verify || '确认' }}
        </el-button>
      </span>
    </el-dialog>
    <!-- //设置 -->
    <el-dialog
      :title="lang.myset || '设置'"
      :visible.sync="setVisible"
      width="28%"
      class="set"
    >
      <div
        slot="title"
        style="
          font-size: 20px;
          border-bottom: 1px solid #000;
          padding-bottom: 10px;
        "
      >
        {{ lang.myset }}
      </div>

      <div class="set-content">
        <div class="set-item">
          <div class="set-left">
            <div class="name">{{ lang.Candletype || '蜡烛图类型1' }}</div>
            <el-select
              @change="ktypechangge"
              style="width: 90px"
              v-model="kvalue"
              :placeholder="lang.Pleaseselect || '请选择'"
            >
              <el-option
                v-for="item in kclassify"
                :key="item.value"
                :label="lang[item.label]"
                :value="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="set-right">
            <div class="name">{{ lang.Gridlinedisplay || '网格线显示' }}</div>
            <el-switch @change="wgchange" v-model="wgshow"></el-switch>
          </div>
        </div>
        <div class="set-item">
          <div class="set-left">
            <div class="name">
              {{ lang.Maximumpricedisplay || '最高价显示' }}
            </div>
            <el-switch @change="highchange" v-model="highshow"></el-switch>
          </div>
          <div class="set-right">
            <div class="name">
              {{ lang.Lowestpricedisplay || '最低价显示' }}
            </div>
            <el-switch @change="lowchange" v-model="lowshow"></el-switch>
          </div>
        </div>
        <div class="set-item">
          <div class="set-left">
            <div class="name">{{ lang.Invertedcoordinates || '倒置坐标' }}</div>
            <el-switch @change="zbchange" v-model="zbshow"></el-switch>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button style="padding: 8px 20px;" @click="pcVisible = false">{{lang.cancel||'取消'}}</el-button> -->
        <el-button
          style="padding: 8px 20px"
          type="primary"
          @click="Restoredefault"
        >
          {{ lang.Restoredefault || '恢复默认' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 用户信息弹窗 -->
    <el-dialog
      class="userinfo"
      ref="userinfoelm"
      :visible.sync="userinfoVisible"
      width="740px"
      top="10px"
      bottom="10px"
      center
    >
      <div slot="title" style="font-size: 18px; color: #000; text-align: left">
        {{ lang.usercenter || '个人中心' }}
      </div>
      <div style="display: flex">
        <div style="width: 28%; margin-right: 20px">
          <div class="email">
            <div
              class="name"
              @click="btnclick(0)"
              :style="{
                color: currentNumber == 0 ? '#fff' : '#000',
                backgroundColor: currentNumber == 0 ? '#0166fc' : '#fff',
                height: '40px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 30px',
              }"
            >
              {{ lang.Billdetails || '账单详情' }}
            </div>
          </div>
          <div
            class="email pwd"
            @click="btnclick(1)"
            :style="{
              color: currentNumber == 1 ? '#fff' : '#000',
              backgroundColor: currentNumber == 1 ? '#0166fc' : '#fff',
              height: '40px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 30px',
            }"
          >
            <div class="name">{{ lang.Golddeposit || '入金' }}</div>
          </div>
          <div
            class="email pwd"
            @click="btnclick(2)"
            :style="{
              color: currentNumber == 2 ? '#fff' : '#000',
              backgroundColor: currentNumber == 2 ? '#0166fc' : '#fff',
              height: '40px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 30px',
            }"
          >
            <div class="name">{{ lang.Withdrawaloffunds || '出金' }}</div>
          </div>
          <div
            class="email pwd"
            @click="btnclick(3)"
            :style="{
              color: currentNumber == 3 ? '#fff' : '#000',
              backgroundColor: currentNumber == 3 ? '#0166fc' : '#fff',
              height: '40px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 30px',
            }"
          >
            <div class="name">{{ lang.wallet || '钱包' }}</div>
          </div>
          <div
            class="email pwd"
            @click="btnclick(4)"
            :style="{
              color: currentNumber == 4 ? '#fff' : '#000',
              backgroundColor: currentNumber == 4 ? '#0166fc' : '#fff',
              height: '40px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 30px',
            }"
          >
            <div class="name">
              {{ lang.Realnameauthentication || '实名认证' }}
            </div>
          </div>
          <div
            class="email pwd"
            @click="btnclick(5)"
            :style="{
              color: currentNumber == 5 ? '#fff' : '#000',
              backgroundColor: currentNumber == 5 ? '#0166fc' : '#fff',
              height: '40px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 30px',
            }"
          >
            <div class="name">{{ lang.Invitefriends || '邀请好友' }}</div>
          </div>
          <div
            class="email pwd"
            @click="btnclick(6)"
            :style="{
              color: currentNumber == 6 ? '#fff' : '#000',
              backgroundColor: currentNumber == 6 ? '#0166fc' : '#fff',
              height: '40px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 30px',
            }"
          >
            <div class="name">{{ lang.Changepassword || '修改密码' }}</div>
          </div>
          <div
            class="email pwd"
            @click="btnclick(7)"
            :style="{
              color: currentNumber == 7 ? '#fff' : '#000',
              backgroundColor: currentNumber == 7 ? '#0166fc' : '#fff',
              height: '40px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 30px',
            }"
          >
            <div class="name">{{ lang.Logout || '退出登录' }}</div>
          </div>
        </div>
        <div
          style="
            width: 70%;
            border: 4px solid rgb(252, 252, 252);
            border-radius: 30px;
            min-height: 570px;
          "
        >
          <div
            v-if="currentNumber == 0"
            style="
              background-color: #fff;
              border-radius: 20px;
              width: 100%;
              height: 100%;
            "
          >
            <div style="display: flex; justify-content: center">
              <div
                style="
                  box-shadow: 0 0 5px 2px #ededed;
                  margin-top: 10px;
                  background-color: #fff;
                  width: 95%;
                  height: 100px;
                  border-radius: 15px;
                  /* display: flex; */
                "
              >
                <div
                  style="
                    font-size: 20px;
                    font-weight: 700;
                    padding: 7px;
                    width: 100%;
                    height: 30px;
                  "
                >
                  USD
                </div>
                <div
                  style="padding: 7px; width: 100%; height: 40px; display: flex"
                >
                  <div style="width: 50%">
                    <span
                      style="font-size: 12px; color: rgba(207, 207, 207, 1)"
                    >
                      {{ lang.Availablebalance || '可用' }}
                    </span>
                    <div
                      style="
                        font-size: 15px;
                        font-weight: 700;
                        color: rgba(87, 154, 253, 1);
                      "
                    >
                      {{
                        userinfomoney.money ? userinfomoney.money : '00000000'
                      }}
                    </div>
                  </div>
                  <div style="width: 50%">
                    <span
                      style="font-size: 12px; color: rgba(207, 207, 207, 1)"
                    >
                      {{ lang.Lockedbalance || '冻结' }}
                    </span>
                    <div
                      style="
                        font-size: 15px;
                        font-weight: 700;
                        color: rgba(0, 0, 0, 1);
                      "
                    >
                      {{
                        userinfomoney.money ? userinfomoney.money : '00000000'
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                font-size: 13px;
                font-weight: 600;
                padding: 17px;
                width: 100%;
                height: 30px;
              "
            >
              {{ lang.Financialrecords || '财务记录' }}
            </div>
            <div style="width: 100%; height: 390px">
              <el-empty
                v-if="userhistory == ''"
                :description="lang.Nodata || '暂无数据'"
              ></el-empty>
              <div v-else style="height: 95%; overflow-y: scroll">
                <div v-for="(item, index) in userhistory" :key="index">
                  <div style="padding: 10px 25px">
                    <div style="display: flex; justify-content: space-between">
                      <span style="font-size: 12px">
                        <span style="color: rgba(207, 207, 207, 1)">
                          {{ lang.quantity || '数量' }}
                        </span>
                        {{ item.current_price }}
                      </span>
                      <span style="font-size: 12px">
                        <span
                          style="font-size: 12px; color: rgba(207, 207, 207, 1)"
                        >
                          {{ lang.record || '记录' }}
                        </span>
                        {{ item.id }}
                      </span>
                    </div>
                    <div style="margin-top: 5px">
                      <span style="font-size: 12px">
                        <span
                          style="font-size: 12px; color: rgba(207, 207, 207, 1)"
                        >
                          {{ lang.time || '时间' }}
                        </span>
                        {{ date(item.createtime) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="currentNumber == 1"
            :style="{
              backgroundColor: '#fff',
              borderRadius: '20px',
              width: '100%',
              height: rujinflag == 1 ? '690px' : '570px',
            }"
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 15px;
              "
            >
              <div
                @click="inmoney(1)"
                :style="{
                  backgroundColor:
                    rujinflag == 1 ? '#0166fc' : 'rgba(203,203,203,1)',
                  width: '49%',
                  borderRadius: '25px',
                  padding: '10px',
                  marginRight: '5px',
                }"
              >
                <div
                  style="
                    font-size: 14px;
                    font-weight: 700;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  {{ lang.Digitalcurrency || '数字货币' }}
                </div>
              </div>
              <div
                @click="inmoney(2)"
                :style="{
                  backgroundColor:
                    rujinflag == 2 ? '#0166fc' : 'rgba(203,203,203,1)',
                  width: '49%',
                  borderRadius: '25px',
                  padding: '10px',
                  marginRight: '5px',
                }"
              >
                <div
                  style="
                    font-size: 14px;
                    font-weight: 700;
                    display: flex;
                    color: #fff;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  {{ lang.Bankcard || '银行卡' }}
                </div>
              </div>
            </div>
            <div v-if="rujinflag == 1" style="padding: 0 20px">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 40px;
                "
              >
                <span>{{ langSelectivenetwork || '选择网络' }}</span>
                <el-select
                  v-model="network"
                  :placeholder="lang.Pleaseselect || '请选择'"
                  size="small"
                  @change="networkchoose"
                >
                  <el-option
                    v-for="item in networkList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.address + '/' + item.id"
                  ></el-option>
                </el-select>
              </div>
              <div
                style="
                  margin-top: 10px;
                  display: flex;
                  justify-content: space-between;
                  /* align-items: center; */
                  height: 150px;
                "
              >
                <span style="margin-top: 10px">
                  {{ lang.Walletaddress || '钱包地址' }}
                </span>
                <div style="width: 200px; height: 130px">
                  <el-image
                    v-if="walletaddressimg"
                    :src="walletaddressimg"
                  ></el-image>
                  <el-image
                    v-if="!walletaddressimg"
                    src="https://bj.bcebos.com/qr-code/2403141902e65989931c.jpg"
                    style="width: 100px; height: 100px"
                  ></el-image>
                  <div style="display: flex; align-items: center">
                    {{ walletaddresstext ? walletaddresstext : 'xxxxxxxx' }}
                    <img
                      src="../../assets/image/copy.svg"
                      @click="copyText(walletaddresstext)"
                      style="width: 15px; height: 15px; margin-left: 10px"
                    />
                  </div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 40px;
                "
              >
                <span>{{ lang.Amountmoneycharged || '充币数量' }}</span>
                <el-input
                  v-model="chongbinum"
                  :placeholder="lang.enteramountmoney || '请输入充币数量'"
                  size="small"
                  style="width: 205px"
                ></el-input>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 150px;
                "
              >
                <span style="margin-top: -80px">
                  {{ lang.Uploadcredentials || '上传凭证' }}
                </span>
                <div
                  style="
                    width: 270px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div
                    v-if="pingzhengimgURL"
                    style="
                      width: 270px;
                      height: 150px;
                      position: relative;
                      display: flex;
                      justify-content: center;
                    "
                  >
                    <span
                      style="
                        width: 20px;
                        height: 20px;
                        background-color: rgba(255, 255, 255, 0.7);
                        color: #000;
                        position: absolute;
                        right: 6px;
                        top: 13px;
                        font-size: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                      @click="deleteimg"
                    >
                      ×
                    </span>
                    <img
                      :src="pingzhengimgURL"
                      mode="aspectFit"
                      style="height: 100%; margin-top: 10px"
                    />
                  </div>
                  <el-upload
                    v-else
                    class="el-upload-dragger"
                    drag
                    action="https://usdt.yanshi001.cc/?s=/ApiImageupload/uploadImg/aid/1/platform/h5/"
                    :on-success="handleSuccess"
                    accept="image/*"
                  >
                    <i class="el-icon-upload"></i>
                  </el-upload>
                </div>
              </div>
              <div style="margin-top: 20px; padding: 10px" @click="rujinsubmit">
                <div
                  style="
                    width: 100%;
                    height: 40px;
                    background-color: #0166fc;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span style="color: #fff; font-size: 16px; font-weight: 700">
                    {{ lang.submit || '提交' }}
                  </span>
                </div>
              </div>
              <div style="height: 120px">
                <span style="font-weight: 700; padding-left: 5px">
                  {{ lang.Depositrecord || '入金记录' }}12
                </span>
                <div
                  style="
                    width: 100%;
                    height: 100px;
                    overflow-y: scroll;
                    margin-top: 10px;
                  "
                >
                  <div v-for="(item, index) in rujinhistory" :key="index">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 40px;
                        padding: 10px 5px;
                      "
                    >
                      <div>
                        <div>
                          <span style="color: #d0d0d0">
                            {{ lang.Rechargeamount || '充值金额' }}
                          </span>
                          <span style="font-weight: 700; margin-left: 10px">
                            {{ item.money }}
                          </span>
                        </div>
                        <div style="padding: 5px 0">
                          <span style="color: #d0d0d0">
                            {{ lang.Rechargetime || '充值时间' }}
                          </span>
                          <span style="font-weight: 700; margin-left: 10px">
                            {{ item.createtime }}
                          </span>
                        </div>
                      </div>
                      <div>
                        <span
                          :style="{
                            color:
                              item.status == 0
                                ? 'red'
                                : item.status == 1
                                ? 'green'
                                : 'blue',
                          }"
                        >
                          {{
                            item.status == 0
                              ? lang.reviewed || '待审核'
                              : item.status == 1
                              ? lang.Rechargesuccessfully || '充值成功'
                              : lang.Rechargefailure || '充值失败'
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="rujinflag == 2" style="padding: 0 20px">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 40px;
                "
              >
                <span>{{ lang.Receivingcurrency || '收款货币' }}</span>
                <el-select
                  v-model="rujinbank"
                  :placeholder="lang.Pleaseselect || '请选择'"
                  size="small"
                  @change="bankchoose"
                >
                  <el-option
                    v-for="item in bankList"
                    :key="item.id"
                    :label="item.bank_name"
                    :value="item.bank_address + '/' + item.id"
                  ></el-option>
                </el-select>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 40px;
                "
              >
                <span>{{ lang.Amountmoneycharged || '充币数量' }}</span>
                <el-input
                  v-model="banknum"
                  :placeholder="lang.enteramountmoney || '请输入充值金额'"
                  size="small"
                  style="width: 205px"
                ></el-input>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 150px;
                "
              >
                <span style="margin-top: -80px">
                  {{ lang.Uploadcredentials || '上传凭证' }}
                </span>
                <div
                  style="
                    width: 270px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <div
                    v-if="pingzhengimgURL2"
                    style="
                      width: 270px;
                      height: 150px;
                      position: relative;
                      display: flex;
                      justify-content: center;
                    "
                  >
                    <span
                      style="
                        width: 20px;
                        height: 20px;
                        background-color: rgba(255, 255, 255, 0.7);
                        color: #000;
                        position: absolute;
                        right: 6px;
                        top: 13px;
                        font-size: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                      @click="deleteimg"
                    >
                      ×
                    </span>
                    <img
                      :src="pingzhengimgURL2"
                      mode="aspectFit"
                      style="height: 100%; margin-top: 10px"
                    />
                  </div>
                  <el-upload
                    v-else
                    class="el-upload-dragger"
                    drag
                    action="https://usdt.yanshi001.cc/?s=/ApiImageupload/uploadImg/aid/1/platform/h5/"
                    :on-success="handleSuccess"
                    accept="image/*"
                  >
                    <i class="el-icon-upload"></i>
                  </el-upload>
                </div>
              </div>
              <div style="margin-top: 20px; padding: 10px" @click="rujinsubmit">
                <div
                  style="
                    width: 100%;
                    height: 40px;
                    background-color: #0166fc;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span style="color: #fff; font-size: 16px; font-weight: 700">
                    {{ lang.submit || '提交' }}
                  </span>
                </div>
              </div>
              <div style="height: 160px">
                <span style="font-weight: 700; padding-left: 5px">
                  {{ lang.Depositrecord || '入金记录' }}
                </span>
                <div
                  style="
                    width: 100%;
                    height: 150px;
                    overflow-y: scroll;
                    margin-top: 10px;
                  "
                >
                  <div v-for="(item, index) in rujinhistory" :key="index">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 40px;
                        padding: 10px 5px;
                      "
                    >
                      <div>
                        <div>
                          <span style="color: #d0d0d0">
                            {{ lang.Rechargeamount || '充值金额' }}
                          </span>
                          <span style="font-weight: 700; margin-left: 10px">
                            {{ item.money }}
                          </span>
                        </div>
                        <div style="padding: 5px 0">
                          <span style="color: #d0d0d0">
                            {{ lang.Rechargetime || '充值时间' }}
                          </span>
                          <span style="font-weight: 700; margin-left: 10px">
                            {{ item.createtime }}
                          </span>
                        </div>
                      </div>
                      <div>
                        <span
                          :style="{
                            color:
                              item.status == 0
                                ? 'red'
                                : item.status == 1
                                ? 'green'
                                : 'blue',
                          }"
                        >
                          {{
                            item.status == 0
                              ? lang.reviewed || '待审核'
                              : item.status == 1
                              ? lang.Rechargesuccessfully || '充值成功'
                              : lang.Rechargefailure || '充值失败'
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 出金 -->
          <div
            v-if="currentNumber == 2"
            style="border-radius: 20px; width: 100%; height: 570px"
          >
            <div style="display: flex; justify-content: center">
              <div
                style="
                  margin-top: 10px;
                  width: 95%;
                  height: 100px;
                  border-radius: 15px;
                "
              >
                <div style="font-size: 20px; font-weight: 700; padding: 7px">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      padding: 15px;
                    "
                  >
                    <div
                      @click="runmoney(1)"
                      :style="{
                        backgroundColor:
                          chujinflag == 1 ? '#0166fc' : 'rgba(203,203,203,1)',
                        width: '49%',
                        borderRadius: '25px',
                        padding: '10px',
                        marginRight: '5px',
                      }"
                    >
                      <div
                        style="
                          font-size: 14px;
                          font-weight: 700;
                          color: #fff;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        {{ lang.Digitalcurrency || '数字货币' }}
                      </div>
                    </div>
                    <div
                      @click="runmoney(2)"
                      :style="{
                        backgroundColor:
                          chujinflag == 2 ? '#0166fc' : 'rgba(203,203,203,1)',
                        width: '49%',
                        borderRadius: '25px',
                        padding: '10px',
                        marginRight: '5px',
                      }"
                    >
                      <div
                        style="
                          font-size: 14px;
                          font-weight: 700;
                          display: flex;
                          color: #fff;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        {{ lang.Bankcard || '银行卡' }}
                      </div>
                    </div>
                  </div>
                  <div v-if="chujinflag == 1" style="padding: 0 20px">
                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.currency || '货币' }}
                      </span>
                      <el-select
                        style="width: 360px"
                        v-model="currency1"
                        :placeholder="lang.Pleaseselect || '请选择'"
                        size="small"
                        :filterable="true"
                        @change="currencyIndex1"
                      >
                        <el-option
                          v-for="item in currencyData"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.Withdrawaladdress || '提币地址' }}
                      </span>
                      <el-select
                        style="width: 360px"
                        v-model="tbaddress"
                        :placeholder="
                          lang.selectwithdrawaladdress || '请选择提币地址'
                        "
                        size="small"
                        :no-data-text="
                          lang.setdigitalcurrencyaddress || '请先设置地址'
                        "
                      >
                        <el-option
                          v-for="item in currencyData[currencyIndex]
                            .currency_list"
                          :key="item.id"
                          :label="item.usdt_address"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.quantity || '数量' }}
                      </span>
                      <el-input
                        @input="computenum"
                        v-model="currencynum"
                        :placeholder="lang.quantity || '数量'"
                        size="small"
                        style="width: 360px"
                      ></el-input>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.remark || '备注' }}
                      </span>
                      <el-input
                        type="textarea"
                        :rows="1"
                        v-model="remark"
                        :placeholder="lang.remark || '备注'"
                        size="small"
                        style="width: 360px; height: 32px"
                      ></el-input>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.Fraistraitement || '手续费' }}
                      </span>
                      <div
                        v-if="type1 == 1"
                        style="width: 360px; font-size: 14px; color: #606266"
                      >
                        {{ sxf }}
                      </div>
                      <div
                        v-if="type1 == 2"
                        style="width: 360px; font-size: 14px; color: #606266"
                      >
                        {{ sxf }}%
                      </div>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.Estimatedamountreceived || '预计到账数量' }}
                      </span>
                      <div
                        style="width: 360px; font-size: 14px; color: #606266"
                      >
                        {{ dzCount }}
                      </div>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.tBalance || '余额' }}
                      </span>
                      <div
                        style="width: 360px; font-size: 14px; color: #606266"
                      >
                        {{ usermoney }}
                      </div>
                    </div>
                  </div>
                  <!-- //银行卡 -->
                  <div v-if="chujinflag == 2" style="padding: 0 20px">
                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.currency || '货币' }}
                      </span>
                      <el-select
                        style="width: 360px"
                        v-model="bank"
                        :placeholder="lang.Pleaseselect || '请选择'"
                        size="small"
                        :filterable="true"
                        @change="currencyIndex2"
                      >
                        <el-option
                          v-for="item in bankData"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.Payeeaccount || '收款人' }}
                      </span>
                      <el-select
                        style="width: 360px"
                        v-model="bankaccount"
                        :placeholder="lang.Payeeaccount || '请选择账号'"
                        size="small"
                        :no-data-text="
                          lang.setcollectionaccount || '请先设置账号'
                        "
                      >
                        <el-option
                          v-for="item in bankData[bankIndex].bank_list"
                          :key="item.id"
                          :label="item.bank_account"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.quantity || '数量' }}
                      </span>
                      <el-input
                        @input="computenum"
                        v-model="currencynum"
                        :placeholder="lang.quantity || '数量'"
                        size="small"
                        style="width: 360px"
                      ></el-input>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.remark || '备注' }}
                      </span>
                      <el-input
                        type="textarea"
                        :rows="1"
                        v-model="remark"
                        :placeholder="lang.remark || '备注'"
                        size="small"
                        style="width: 360px; height: 32px"
                      ></el-input>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.Fraistraitement || '手续费' }}
                      </span>
                      <div
                        v-if="type == 1"
                        style="width: 360px; font-size: 14px; color: #606266"
                      >
                        {{ sxf }}
                      </div>
                      <div
                        v-if="type == 2"
                        style="width: 360px; font-size: 14px; color: #606266"
                      >
                        {{ sxf }}%
                      </div>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.Estimatedamountreceived || '预计到账数量' }}
                      </span>
                      <div
                        style="width: 360px; font-size: 14px; color: #606266"
                      >
                        {{ dzCount }}
                      </div>
                    </div>

                    <div style="display: flex; margin-bottom: 18px">
                      <span
                        style="
                          width: 132px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                          margin-right: 10px;
                          font-size: 14px;
                          color: #606266;
                        "
                      >
                        {{ lang.tBalance || '余额' }}
                      </span>
                      <div
                        style="width: 360px; font-size: 14px; color: #606266"
                      >
                        {{ usermoney }}
                      </div>
                    </div>
                  </div>

                  <div
                    style="margin-top: 20px; padding: 10px"
                    @click="submitchujin"
                  >
                    <div
                      style="
                        width: 100%;
                        height: 40px;
                        background-color: #0166fc;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <span
                        style="color: #fff; font-size: 16px; font-weight: 700"
                      >
                        提交
                      </span>
                    </div>
                  </div>

                  <div style="height: 100px; font-size: 16px">
                    <span style="font-weight: 700; padding-left: 5px">
                      {{ lang.sWithdrawalrecord || '出金记录' }}
                    </span>
                    <div
                      style="
                        width: 100%;
                        height: 70px;
                        overflow-y: scroll;
                        margin-top: 10px;
                      "
                    >
                      <div v-for="(item, index) in chujinhistory" :key="index">
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            height: 40px;
                            padding: 10px 5px;
                            font-weight: 300;
                          "
                        >
                          <div>
                            <div>
                              <span style="color: #d0d0d0">
                                {{ lang.Withdrawalamount || '提现金额' }}
                              </span>
                              <span style="font-weight: 700; margin-left: 10px">
                                {{ item.money }}
                              </span>
                            </div>
                            <div style="padding: 5px 0">
                              <span style="color: #d0d0d0">
                                {{ lang.Withdrawaltime || '提现时间' }}
                              </span>
                              <span style="font-weight: 700; margin-left: 10px">
                                {{ item.createtime }}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span
                              style="font-weight: 700"
                              :style="{
                                color:
                                  item.status == 0
                                    ? 'red'
                                    : item.status == 1
                                    ? 'green'
                                    : 'blue',
                              }"
                            >
                              {{
                                item.status == 0
                                  ? lang.reviewed || '待审核'
                                  : item.status == 1
                                  ? lang.Rechargesuccessfully || '充值成功'
                                  : lang.Rechargefailure || '充值失败'
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="currentNumber == 3"
            style="
              background-color: #fff;
              border-radius: 20px;
              width: 100%;
              height: 100%;
            "
          >
            <div v-if="!addbankflag">
              <div style="display: flex; justify-content: center">
                <div
                  style="
                    margin-top: 10px;
                    background-color: #fff;
                    width: 95%;
                    height: 60px;
                  "
                >
                  <div style="font-size: 20px; font-weight: 700; padding: 7px">
                    <div>
                      <span style="font-size: 14px; font-weight: 700">
                        {{ lang.tBalance || '余额' }}
                      </span>
                    </div>
                    <div>
                      <span
                        style="
                          font-size: 14px;
                          color: #0199fd;
                          font-weight: 700;
                        "
                      >
                        {{ useryue.money }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: center">
                <div
                  style="
                    margin-top: 10px;
                    background-color: #fff;
                    width: 95%;
                    min-height: 100px;
                  "
                >
                  <div style="font-size: 20px; font-weight: 700; padding: 7px">
                    <div>
                      <span style="font-size: 14px; font-weight: 700">
                        {{ lang.Bankcard || '银行卡' }}
                      </span>
                    </div>

                    <div
                      :style="{
                        display: 'flex',
                        overflowX: walletbank.length >= 2 ? 'scroll' : '',
                      }"
                    >
                      <div
                        v-for="(item, index) in walletbank"
                        style="padding: 5px 10px"
                        :key="index"
                      >
                        <div
                          style="
                            width: 180px;
                            min-height: 40px;
                            border: 1px solid #eee;
                            background-color: #fff;
                            box-shadow: 5px -0 5px #ededed, -5px 0 5px #ededed;
                          "
                        >
                          <div
                            style="
                              position: relative;
                              width: 100%;
                              height: 25px;
                              border-bottom: 1px solid #eee;
                            "
                          >
                            <div
                              style="
                                position: absolute;
                                right: 0;
                                padding: 5 5px;
                              "
                            >
                              <img
                                src="../../assets/image/close.svg"
                                style="width: 25px; height: 25px"
                                @click="deleteUserBorU(item.id, 1)"
                              />
                            </div>
                          </div>
                          <div style="padding: 5px">
                            <div
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                "
                              >
                                {{ lang.Receivingcurrency || '收款货币' }}：
                              </span>
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                  text-align: right;
                                "
                              >
                                {{ item.name }}
                              </span>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                "
                              >
                                {{ lang.Bankname || '银行名称' }}：
                              </span>
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                  text-align: right;
                                "
                              >
                                {{ item.bank_name }}
                              </span>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                "
                              >
                                {{ lang.Payeeaccount || '收款人账户' }}：
                              </span>
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                  text-align: right;
                                "
                              >
                                {{ item.bank_account }}
                              </span>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                "
                              >
                                {{ lang.payee || '收款人' }}：
                              </span>
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                  text-align: right;
                                "
                              >
                                {{ item.bank_user }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="padding: 5px 10px">
                        <div
                          @click="addbank(1)"
                          :style="{
                            width: '180px',
                            height: walletbank == '' ? '40px' : '100px',
                            border: '1px solid #eee',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#fff',
                            boxShadow: '5px -0 5px #ededed, -5px 0 5px #ededed',
                          }"
                        >
                          <span style="font-size: 30px; font-weight: 800">
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: center">
                <div
                  style="
                    margin-top: 10px;
                    background-color: #fff;
                    width: 95%;
                    min-height: 100px;
                  "
                >
                  <div style="font-size: 20px; font-weight: 700; padding: 7px">
                    <div>
                      <span style="font-size: 14px; font-weight: 700">
                        {{ lang.Digitalcurrency || '数字货币' }}
                      </span>
                    </div>
                    <div
                      v-if="walletusdt"
                      :style="{
                        display: 'flex',
                        overflowX: walletusdt.length >= 2 ? 'scroll' : '',
                      }"
                    >
                      <div
                        v-for="(item, index) in walletusdt"
                        style="padding: 5px 10px"
                        :key="index"
                      >
                        <div
                          style="
                            width: 180px;
                            min-height: 40px;
                            border: 1px solid #eee;
                            background-color: #fff;
                            box-shadow: 5px -0 5px #ededed, -5px 0 5px #ededed;
                          "
                        >
                          <div
                            style="
                              position: relative;
                              width: 100%;
                              height: 25px;
                              border-bottom: 1px solid #eee;
                            "
                          >
                            <div
                              style="
                                position: absolute;
                                right: 0;
                                padding: 5 5px;
                              "
                            >
                              <img
                                src="../../assets/image/close.svg"
                                style="width: 25px; height: 25px"
                                @click="deleteUserBorU(item.id, 2)"
                              />
                            </div>
                          </div>
                          <div style="padding: 5px">
                            <div
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                "
                              >
                                {{ lang.currency || '货币' }}：
                              </span>
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                  text-align: right;
                                "
                              >
                                {{ item.name }}
                              </span>
                            </div>
                            <div
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                "
                              >
                                {{ lang.Withdrawaladdress || '提币地址' }}：
                              </span>
                              <span
                                style="
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #000;
                                  text-align: right;
                                "
                              >
                                {{ item.usdt_address }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="padding: 5px 10px">
                        <div
                          @click="addbank(2)"
                          :style="{
                            width: '180px',
                            height: walletbank == '' ? '40px' : '68px',
                            border: '1px solid #eee',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#fff',
                            boxShadow: '5px -0 5px #ededed, -5px 0 5px #ededed',
                          }"
                        >
                          <span style="font-size: 30px; font-weight: 800">
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="addbankflag"
              style="display: flex; justify-content: center"
            >
              <div style="width: 100%">
                <div
                  style="
                    margin-top: 10px;
                    background-color: #fff;
                    width: 95%;
                    height: 35px;
                  "
                >
                  <div style="font-size: 14px; font-weight: 700; padding: 7px">
                    <span @click="addbank">{{ lang.wallet || '钱包' }}</span>
                    <span>&nbsp;&nbsp;/&nbsp;&nbsp;{{ menuindex }}</span>
                  </div>
                </div>
                <div
                  v-if="addflag == 1"
                  style="
                    background-color: #fff;
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                  "
                >
                  <div
                    style="width: 100%; display: flex; justify-content: center"
                  >
                    <div style="width: 95%; padding: 10px">
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 5px 0;
                        "
                      >
                        <span style="text-align: right; width: 24%">
                          {{ lang.currency || '货币' }}
                        </span>
                        <el-select
                          v-model="bankHB"
                          :placeholder="lang.Pleaseselect || '请选择'"
                          size="small"
                          style="width: 74%"
                          @change="bankHBchoose"
                        >
                          <el-option
                            v-for="item in bankHBlist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </div>

                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 5px 0;
                        "
                      >
                        <span style="text-align: right; width: 24%">
                          {{ lang.Bankname || '银行名称' }}
                        </span>
                        <el-input
                          v-model="bank_name"
                          :placeholder="lang.Bankname || '银行名称'"
                          size="small"
                          style="width: 74%; margin: 10px 0"
                        ></el-input>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 5px 0;
                        "
                      >
                        <span style="text-align: right; width: 24%">
                          {{ lang.Bankaddress || '银行地址' }}
                        </span>
                        <el-input
                          v-model="bank_address"
                          :placeholder="lang.Bankaddress || '银行地址'"
                          size="small"
                          style="width: 74%; margin: 10px 0"
                        ></el-input>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 5px 0;
                        "
                      >
                        <span style="text-align: right; width: 24%">SWIFT</span>
                        <el-input
                          v-model="SWIFT"
                          placeholder="SWIFT"
                          size="small"
                          style="width: 74%; margin: 10px 0"
                        ></el-input>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 5px 0;
                        "
                      >
                        <span style="text-align: right; width: 24%">
                          {{ lang.payee || '收款人' }}
                        </span>
                        <el-input
                          v-model="bank_user"
                          :placeholder="lang.payee || '收款人'"
                          size="small"
                          style="width: 74%; margin: 10px 0"
                        ></el-input>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 5px 0;
                        "
                      >
                        <span style="text-align: right; width: 24%">
                          {{ lang.Payeeaccount || '收款人账户' }}
                        </span>
                        <el-input
                          v-model="bank_account"
                          :placeholder="lang.Payeeaccount || '收款人账户'"
                          size="small"
                          style="width: 74%; margin: 10px 0"
                        ></el-input>
                      </div>
                      <div
                        style="
                          margin-top: 20px;
                          padding: 10px;
                          width: 100%;
                          display: flex;
                          justify-content: center;
                        "
                        @click="addsubmit(1)"
                      >
                        <div
                          style="
                            width: 70%;
                            height: 40px;
                            background-color: #0166fc;
                            border-radius: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <span
                            style="
                              color: #fff;
                              font-size: 16px;
                              font-weight: 700;
                            "
                          >
                            {{ lang.submit || '提交' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="addflag == 2"
                  style="
                    background-color: #fff;
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                  "
                >
                  <div
                    style="width: 100%; display: flex; justify-content: center"
                  >
                    <div style="width: 95%; padding: 10px">
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 5px 0;
                        "
                      >
                        <span style="text-align: right; width: 24%">
                          {{ lang.currency || '货币' }}
                        </span>
                        <el-select
                          v-model="usdtHB"
                          :placeholder="lang.Pleaseselect || '请选择'"
                          size="small"
                          style="width: 74%"
                          @change="usdtHBchoose"
                        >
                          <el-option
                            v-for="item in usdtHBlist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 5px 0;
                        "
                      >
                        <span style="text-align: right; width: 24%">
                          {{ lang.Walletaddress || '钱包地址' }}
                        </span>
                        <el-input
                          v-model="usdt_address"
                          :placeholder="lang.Walletaddress || '钱包地址'"
                          size="small"
                          style="width: 74%; margin: 10px 0"
                        ></el-input>
                      </div>
                      <div
                        style="
                          margin-top: 20px;
                          padding: 10px;
                          width: 100%;
                          display: flex;
                          justify-content: center;
                        "
                        @click="addsubmit(2)"
                      >
                        <div
                          style="
                            width: 70%;
                            height: 40px;
                            background-color: #0166fc;
                            border-radius: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <span
                            style="
                              color: #fff;
                              font-size: 16px;
                              font-weight: 700;
                            "
                          >
                            {{ lang.submit || '提交' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="currentNumber == 4"
            style="border-radius: 20px; width: 100%; height: 570px"
          >
            <div style="display: flex; justify-content: center">
              <div
                style="
                  margin-top: 10px;
                  width: 95%;
                  height: 100px;
                  border-radius: 15px;
                  padding: 10px;
                "
              >
                <!-- <div style="font-size: 20px; font-weight: 700; padding: 7px">
                </div>-->
                <div
                  v-if="rzstatus == -1 || rzstatus == 2"
                  style="display: flex; flex-direction: column"
                >
                  <div
                    style="
                      color: rgb(26 34 43 / 1);
                      font-size: 12px;
                      padding-left: 10px;
                      margin-bottom: 10px;
                    "
                  >
                    {{ lang.uploadsf || '请上传护照/证件的正反面' }}
                  </div>
                  <div v-if="rzstatus == 2" style="padding-left: 10px">
                    {{ lang.Auditfailure || '失败原因' }}:
                    <span style="color: red; margin-left: 10px">
                      {{ tips }}
                    </span>
                  </div>
                  <div
                    style="
                      padding: 10px;
                      background-color: rgb(255 255 255 / 1);
                    "
                  >
                    <div
                      style="
                        display: flex;
                        border-radius: 4px;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;
                        background-color: rgb(248 248 248 / 1);
                        margin-bottom: 10px;
                      "
                    >
                      <div style="padding-left: 8px">
                        <div
                          style="
                            font-size: 14px;
                            margin-bottom: 6px;
                            color: #000;
                          "
                        >
                          {{ lang.rightside || '正面' }}
                        </div>
                        <div style="font-size: 12px; color: #cbcbcb">
                          {{
                            lang.UploadfrontpassportID || '上傳護照/證件正面'
                          }}
                        </div>
                      </div>
                      <div style="width: 186px; height: 122px">
                        <el-upload
                          class="avatar-uploader"
                          action="https://usdt.yanshi001.cc/?s=/ApiImageupload/uploadImg/aid/1/platform/h5/"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                        >
                          <!-- :before-upload="beforeAvatarUpload" -->
                          <img
                            v-if="smrz.imageUrl"
                            :src="smrz.imageUrl"
                            style="width: 186px; height: 122px"
                            class="avatar"
                          />
                          <img
                            v-else
                            src="../../assets/image/smrz.png"
                            style="width: 186px; height: 122px"
                            alt
                          />
                        </el-upload>
                      </div>
                    </div>

                    <div
                      style="
                        display: flex;
                        border-radius: 4px;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;
                        background-color: rgb(248 248 248 / 1);
                        margin-bottom: 10px;
                      "
                    >
                      <div style="padding-left: 8px">
                        <div
                          style="
                            font-size: 14px;
                            margin-bottom: 6px;
                            color: #000;
                          "
                        >
                          {{ lang.Reverseside || '反面' }}
                        </div>
                        <div style="font-size: 12px; color: #cbcbcb">
                          {{ lang.UploadpassportIDback || '上傳護照/證件正面' }}
                        </div>
                      </div>
                      <div style="width: 186px; height: 122px">
                        <el-upload
                          class="avatar-uploader"
                          action="https://usdt.yanshi001.cc/?s=/ApiImageupload/uploadImg/aid/1/platform/h5/"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess1"
                        >
                          <!-- :before-upload="beforeAvatarUpload1" -->
                          <img
                            v-if="smrz.imageUrl1"
                            :src="smrz.imageUrl1"
                            style="width: 186px; height: 122px"
                            class="avatar"
                          />
                          <img
                            v-else
                            src="../../assets/image/smrz.png"
                            style="width: 186px; height: 122px"
                            alt
                          />
                        </el-upload>
                      </div>
                    </div>
                  </div>

                  <div style="margin-top: 10px">
                    <div style="padding: 10px; font-size: 12px">
                      {{ lang.completepersonalinformation || '请完善个人信息' }}
                    </div>
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                      "
                    >
                      <div
                        style="
                          width: 120px;
                          display: flex;
                          justify-content: flex-end;
                          margin-right: 10px;
                        "
                      >
                        {{ lang.name || '姓名' }}
                      </div>
                      <el-input
                        v-model="smrz.name"
                        :placeholder="lang.entername || '请输入姓名'"
                        size="small"
                        style="width: 328px; height: 32px"
                      ></el-input>
                    </div>
                    <div style="display: flex; align-items: center">
                      <div
                        style="
                          width: 120px;
                          display: flex;
                          justify-content: flex-end;
                          margin-right: 10px;
                        "
                      >
                        {{ lang.Passportdocumentnumber || '護照/證件號' }}
                      </div>
                      <el-input
                        v-model="smrz.zjh"
                        :placeholder="lang.enterpassportIDnumber"
                        size="small"
                        style="width: 328px; height: 32px"
                      ></el-input>
                    </div>
                  </div>

                  <div
                    style="margin-top: 20px; padding: 10px"
                    @click="submitNameAuth"
                  >
                    <div
                      style="
                        width: 100%;
                        height: 40px;
                        background-color: #0166fc;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <span
                        style="color: #fff; font-size: 16px; font-weight: 700"
                      >
                        确认
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="rzstatus == 1"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 50px;
                    flex-direction: column;
                  "
                >
                  <img
                    src="../../assets/image/gz.png"
                    style="width: 150px; height: 150px"
                    alt
                  />
                  <div
                    style="font-size: 30px; font-weight: 700; margin-top: 16px"
                  >
                    {{ lang.Verified || '已认证' }}
                  </div>
                </div>
                <div
                  v-if="rzstatus == 0"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 50px;
                    flex-direction: column;
                  "
                >
                  <img
                    src="../../assets/image/shz.svg"
                    style="width: 150px; height: 150px"
                    alt
                  />
                  <div
                    style="font-size: 30px; font-weight: 700; margin-top: 16px"
                  >
                    {{ lang.reviewed || '待审核' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="currentNumber == 5"
            style="
              background-color: #fff;
              border-radius: 20px;
              width: 100%;
              height: 100%;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  margin-top: 10px;
                  width: 95%;
                  height: 500px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div>
                  <div style="display: flex; justify-content: center">
                    <span style="color: #d0d0d0; font-size: 13px">
                      {{ lang.yqm || '邀请码' }}
                    </span>
                  </div>
                  <div style="display: flex; justify-content: center">
                    <span
                      style="
                        color: #0166fc;
                        font-size: 20px;
                        font-weight: 700;
                        margin: 15px 0;
                      "
                    >
                      {{ yqminfo.yqcode }}
                    </span>
                  </div>
                  <div style="height: 200px; width: 100%">
                    <div style="display: flex; justify-content: center">
                      <el-image
                        :src="yqminfo.qrcode"
                        style="width: 140px; height: 140px"
                      ></el-image>
                    </div>
                    <div style="margin: 15px 0">
                      <span style="font-size: 13px">{{ yqminfo.link }}</span>
                    </div>
                  </div>
                  <div style="display: flex; justify-content: center">
                    <div
                      @click="copyyqcode(yqminfo.link)"
                      style="
                        width: 200px;
                        height: 40px;
                        background-color: #0166fc;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <span
                        style="color: #fff; font-size: 16px; font-weight: 700"
                      >
                        {{ lang.Copyinvitationlink || '复制邀请链接' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="currentNumber == 6"
            style="
              background-color: #fff;
              border-radius: 20px;
              width: 100%;
              height: 100%;
            "
          >
            <div style="display: flex; justify-content: center">
              <div style="margin-top: 10px; width: 95%">
                <div style="height: 70px">
                  <span>{{ lang.Enteroldpassword || '请输入原密码' }}</span>
                  <el-input
                    v-model="oldpwd"
                    :placeholder="lang.Enteroldpassword || '请输入原密码'"
                    size="small"
                    style="width: 100%; margin: 10px 0"
                  ></el-input>
                </div>
                <div style="height: 70px">
                  <span>{{ lang.Enternewpassword || '请输入新密码' }}</span>
                  <el-input
                    v-model="pwd"
                    :placeholder="lang.Enternewpassword || '请输入新密码'"
                    size="small"
                    style="width: 100%; margin: 10px 0"
                  ></el-input>
                </div>
                <div style="height: 70px">
                  <span>{{ lang.Confirmnewpassword || '再次输入新密码' }}</span>
                  <el-input
                    v-model="againpwd"
                    :placeholder="lang.Enterpassword || '再次输入新密码'"
                    size="small"
                    style="width: 100%; margin: 10px 0"
                  ></el-input>
                </div>
                <div style="margin-top: 20px; padding: 10px" @click="pwdsubmit">
                  <div
                    style="
                      width: 100%;
                      height: 40px;
                      background-color: #0166fc;
                      border-radius: 20px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <span
                      style="color: #fff; font-size: 16px; font-weight: 700"
                    >
                      {{ lang.submit || '提交' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="currentNumber == 7"
            style="
              background-color: #fff;
              border-radius: 20px;
              width: 100%;
              height: 100%;
            "
          ></div>
        </div>
      </div>

      <!-- <div class="button" @click="login">{{ lang.click || '电机' }}</div> -->
    </el-dialog>
  </div>
</template>

<script>
import {
  getIndexData,
  getPrice,
  getlnglist,
  getlanpak,
  getOrderList,
  getTradeList,
  getTradeCreate,
  login,
  register,
  orderUpdate,
  pingCang,
  cancelOrder,
  getKline,
  search,
  getinmoneyinfo,
  getinmoneyhistory,
  chongbiUpdata,
  getyqm,
  pwdUpdata,
  getbanklist,
  getusdtlist,
  delbank,
  delusdt,
  getbankHBlist,
  getusdtHBlist,
  addbank,
  addusdt,
  getWithdraw,
  subWithdraw,
  nameAuth,
  getnameAuth,
  getnameAuthInfo,
  userinfo,
  getOrderlistPrice,
  getPriceData
} from '@/api/index'
import WebSocketClient from '@/utils/webscoket'
import { mapState } from 'vuex'
import * as echarts from 'echarts'
import _ from 'lodash'
import axios from 'axios'
export default {
  name: 'index',
  data() {
    return {
      statusText: [
        {
          text: '1M',
          data: '1',
        },
        {
          text: '5M',
          data: '5',
        },
        {
          text: '30M',
          data: '30',
        },
        {
          text: '1H',
          data: '60',
        },
        {
          text: '2H',
          data: '120',
        },
        {
          text: '4H',
          data: '240',
        },
        {
          text: '1D',
          data: '0',
        },
      ], //选项数据
      limit: 50, //k线图获取多少条数据
      timeflag: '1M',
      type: 1, //type是k线图多少分钟的
      //语言下拉选择
      options: [
        // {
        //   value: "选项1",
        //   label: "黄金糕"
        // }
      ],
      options2: [
        {
          value: '1',
          label: 'Marketprice',
        },
        {
          value: '2',
          label: 'Pendingorder',
        },
      ],
      available_balance: 0, //可用余额
      currentname: '', //当前名字
      currentprice: 0, //当前价格
      // modeflag: "", //市价切换
      options1: [],
      options3: [], //倍数下拉
      value: '',
      value1: '',
      value2: 'Marketprice', //市价
      inputprice: '', //挂单价格
      value3: '', //倍数

      currency: [], //数据货币数据
      ids: [],
      price: [], //价格集合
      currency_type: [], //分类合集
      currentIndex: -1,
      langList: [], //语言列表
      zhisunvalue: false, //止损计算控制
      zhiyingvalue: false, //
      num: 0, //止损
      num1: 0, //止盈
      num2: 0, //买入
      maxnum: 0, //买入最大值
      minnum: 0, //买入最大值
      step: 0, //每次增加数量
      multiple: '', //倍数
      sxf1: 0, //手续费
      meishou_number: 0, //每手数
      mode: 0, //保证金计算方式
      price1: 0, //价格
      computedprice: 0, // 预估保证金
      active: 0, //订单tab切换
      ordertabs: ['Takeposition', 'Pendingorder', 'history'], //订单分类
      orderYk: [], //订单盈亏
      tableData: [
        // {
        //   date: "2016-05-02",
        //   name: "王小虎",
        //   address: "上海市普陀区金沙江路 1518 弄"
        // },
      ],
      chartsData: [],
      currentChartsData: [], //Echarts

      currentId: '', //当前数据id
      loginVisible: false, //登录弹窗
      regVisible: false, //注册弹窗
      emailAccount: '', //邮箱账号
      password: '', //密码
      pwdAgin: '', //再输入密码,
      yqm: '', //邀请码
      emailname: '', //账号名字
      orderVisible: false, //订单弹窗
      orderzy: 0, //止盈
      orderzs: 0, //止损
      pcVisible: false, //平仓弹框
      pcorco: 0, //0平仓 1撤单
      // rawData:[],//k线图数据
      myChart: null,
      option: null,
      currentChartsData: [],
      rawData: [],
      dates: [],
      data: [],
      fullscreen: false,
      thumbnailDataURL: null,
      chart: null,
      imgVisible: false, //截图显示
      setVisible: false, //设置显示
      kclassify: [
        {
          value: '0',
          label: 'solid',
        },
        {
          value: '1',
          label: 'hollow',
        },
        {
          value: '2',
          label: 'Expandedcavity',
        },
        {
          value: '3',
          label: 'fallhollow',
        },
      ],
      kvalue: 'solid',
      rise: '#0166fc', //涨
      fall: '#f23c48', //跌
      wgshow: true, //网格线显示
      highshow: true, //高
      lowshow: true, //低
      zbshow: false, //坐标
      pagenum: 1, //订单分页
      isLoaded: false,
      leftshow: true,
      rightshow: true,
      loading: false, //订单加载
      fullscreenLoading: false, //页面加载
      chartsLoading: false, //页面加载
      keyword: '', //搜索
      userinfoVisible: false, //false, //用户信息弹窗
      currentNumber: 0, //选项卡位置
      loginzt: 0, //0未登录 1登录

      // 个人中心部分
      userhistory: [],
      userinfomoney: [],
      rujinflag: 1,
      // -1
      networkList: [],
      network: '',
      networkid: -1,
      walletaddressimg: '',
      walletaddresstext: '',
      chongbinum: '',
      pingzhengimgURL: '',
      rujinhistory: [],

      bankList: [],
      rujinbank: '',
      bankid: -1,
      banknum: '',
      pingzhengimgURL2: '',
      // -3
      useryue: '',
      walletbank: [],
      walletusdt: [],
      addbankflag: false,
      addflag: 0,
      menuindex: '',
      bankHBlist: [],
      bankHB: '',
      bankHBid: -1,
      bank_name: '',
      bank_address: '',
      SWIFT: '',
      bank_user: '',
      bank_account: '',

      usdtHBlist: [],
      usdtHB: '',
      usdtHBid: -1,
      usdt_address: '',
      // -5
      yqminfo: [],
      // -6
      oldpwd: '',
      pwd: '',
      againpwd: '',
      chujinflag: 1, //出金
      bankData: [], //银行卡
      currencyData: [], //数字货币
      currency1: '', //货币
      tbaddress: '', //提币地址
      currencyIndex: '0', //提币地址数据下标
      currencynum: '', //数字货币数量
      remark: '', //备注
      usermoney: '', //余额
      dzCount: '', //到账数量
      exchange_rate: '',
      bank: '', //银行货币
      bankIndex: '0',
      bankaccount: '', //收款人账户
      sxf: '', //手续费
      type1: '', //手续费类型
      chujinhistory: [],
      smrz: {
        imageUrl: '',
        imageUrl1: '',
        name: '',
        zjh: '',
      }, //实名认证
      rzstatus: '',
      idcode: '',
      name: '',
      visibleIds: [], // 存放可见的 ID 的数组
      scrollTimeout: null,
      timer2: null,
      show: '',
      startPointL:[],
      circleClick:'',
      startPointL1:[],
      endPointL1:[],
      rectClick:'',
      url:''
    }
  },
  async created() {
    this.loginzt = localStorage.getItem('loginzt') || 0
    if (this.loginzt == 1) {
      this.getUserInfo()
    }
    // 如果浏览器视口宽度小于跳转手机端
    // if (window.innerWidth<500) {
    //   window.location.href = 'https://usdt.yanshi001.cc/h5/1.html#/pages/index/index';
    // }
    this.getOrderList()

    await this.getLangList()
    await this.getData()
    await this.getTrade()
    await this.getNowData()
    // await this.getKline();
    // this.getOrderList();
    const langcut = localStorage.getItem('langCut')
    const langcutObject = JSON.parse(langcut)
    if (langcutObject) {
      this.langList.forEach((item) => {
        if (item.id == langcutObject) {
          this.value = item.name
        }
      })
    }
    // WebSocketClient.init();
    // WebSocketClient.socket.addEventListener('message', this.handleMessage);//
    // this.getPrice()
  },
  // beforeDestroy(){
  //   WebSocketClient.close();
  // },
  mounted() {
    // const container = document.querySelector('.main .left')
    // container.addEventListener("scroll", this.handleScroll);
    // container.addEventListener('scroll', this.throttledHandleScroll)
    const debouncedScroll = this.debounce(this.handleScroll1, 500) // 创建一个500ms的防抖函数
    window.addEventListener('scroll', debouncedScroll)
    // window.addEventListener('scroll', this.handleScroll4);
    window.addEventListener('scroll', this.handleScrollWithDelay)
  },
  computed: {
    ...mapState('Lang', ['lang']),
    ...mapState('user', ['loginStatus']),
  },
  methods: {
    getNowData(){
        // if(this.nowdata){
        //   return
        // }
        axios.get(this.url+'/?s=/ApiTrade/quotes&aid=1&platform=h5').then(res=>{
          console.log(res,21375312678);
            this.price = res.data
          this.currentprice = this.price[this.currentId].price
          if(res.data){
            this.getNowData()
          }
        })

        // getPriceData().then(res=>{
        //   // console.log(res);
        //   this.price = res
        //   this.currentprice = this.price[this.currentId].price
        //   if(res){
        //     this.getNowData()
        //   }
        // })
        
      },
     handleMessage(event) {
            const message = JSON.parse(event.data);
            const Rate = (message.data.p-message.data.hc)/message.data.hc*100;
            const data = {
              price:message.data.p,
              name:message.data.c,
              changeRate: Rate.toFixed(2)
            }
            // console.log(data,'000');
            const nameToMatch = message.data.c;
            // console.log(nameToMatch);
              // 遍历this.price对象的每个子对象
              for (const key in this.price) {
                // console.log(key,123);
                if (this.price.hasOwnProperty(key)) {
                  // console.log(this.price[key]);
                      // 如果属性名与nameToMatch相同，则替换数据
                      if (this.price[key].name == nameToMatch) {
                        // console.log(message.data,'80');
                        this.price[key] = data;
                        break; // 找到匹配项后跳出循环
                      }
                    }
              }
              this.currentprice = this.price[this.currentId].price
              // console.log(this.price,897);
          },
    showul(i) {
      this.show = i
      // if (i === this.show) {
      //   this.show =''
      // }
    },
    arraysAreEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false
        }
      }
      return true
    },
    handleScrollWithDelay() {
      // clearTimeout(this.scrollTimeout);
      // clearTimeout(this.timer1)
      if (this.scrollTimeout === null) {
        this.scrollTimeout = setTimeout(() => {
          clearTimeout(this.timer2)
          this.getOrderPrice(this.visibleIds)
          this.scrollTimeout = null // 重置定时器为 null，以便下次滚动时重新设置
        }, 2000)
      }

      // 获取可见的 IDs
      this.visibleIds = []
      const views = document.querySelectorAll('span[data-item-id]')
      views.forEach((view) => {
        const rect = view.getBoundingClientRect()
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0
        if (isVisible) {
          const id = view.getAttribute('data-item-id')
          this.visibleIds.push(id)
        }
      })
    },
    getOrderPrice(ids) {
      if (ids == '') return
      if (ids === '' || !this.arraysAreEqual(ids, this.visibleIds)) return
      clearTimeout(this.timer2)
      getOrderlistPrice({
        ids: ids,
        st: this.active + 1,
      }).then((res) => {
        if (res.datalist) {
          res.datalist.forEach((newData) => {
            // 在原数组中查找与获取到的数据中相同ID的项
            const index = this.tableData.findIndex(
              (item) => item.id === newData.id
            )
            if (index !== -1) {
              // 如果找到了对应的项，则用获取到的数据替换原数组中的数据
              this.$set(this.tableData, index, newData)
            }
          })
          clearTimeout(this.timer2)
          this.timer1 = setTimeout(() => {
            console.log(3214)
            this.getOrderPrice(ids)
          }, 1000)
        }
      })
    },
    // // 获取订单价格实时刷新
    //  getOrderPrice(ids) {
    //   getOrderlistPrice({
    //     ids: ids,
    //     st: this.active + 1
    //   }).then(res=>{
    //     console.log(res);
    //   })

    // },
    // handleScroll4() {
    //   const visibleIds = []
    //   const views = document.querySelectorAll('span[data-item-id]');
    //   views.forEach(view => {
    //     const rect = view.getBoundingClientRect();
    //     const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
    //     if (isVisible) {
    //       const id = view.getAttribute('data-item-id');
    //       // console.log(id);
    //       visibleIds.push(id);
    //     }
    //   });
    //   console.log(visibleIds, '0909');
    //   return visibleIds
    // },
    async getUserInfo() {
      const res = await userinfo()
      if (res.status == 1) {
        const info = res.pagecontent.filter((item) => item.temp === 'userinfo')
        // console.log(info[0].data.userinfo.yqcode,777);

        this.idcode = info[0].data.userinfo.yqcode
        //  if (this.loginzt) {
        this.emailname = info[0].data.userinfo.email
        // }
      }
    },
    date: function (time, format) {
      if (
        format == undefined ||
        format == 'undefined' ||
        format == null ||
        format == ''
      )
        format = 'Y-m-d H:i:s'
      var date = new Date()
      if (time != '' || time > 0) {
        date = new Date(time * 1000)
      }

      var Y = date.getFullYear()
      var m =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      var d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var i =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      format = format.replace('Y', Y)
      format = format.replace('m', m)
      format = format.replace('d', d)
      format = format.replace('H', H)
      format = format.replace('i', i)
      format = format.replace('s', s)
      return format
    },
    // 搜索
    Search() {
      this.currentIndex = 20
      const debounceFunc = (func, delay) => {
        let timer1
        return function () {
          const context = this
          const args = arguments
          clearTimeout(timer1)
          timer1 = setTimeout(() => {
            func.apply(context, args)
          }, delay)
        }
      }
      const debouncedGetData = debounceFunc(() => {
        search(this.keyword).then((res) => {
          // if (res[0].status == 1) {
          // if (res[0].currency == '') {
          //   this.currentIndex = ''
          //   this.currency = ''
          // } else {
          //   this.currency = res[0].currency
          // }
          // }
          if (res.status == 1) {
            if (res.currency == '') {
              this.currentIndex = ''
              this.currency = ''
            } else {
              this.currency = res.currency
              console.log(this.currency, 321)
            }
          }
        })

        console.log(this.currency, 777888)
      }, 200)
      debouncedGetData()
      // search(this.keyword).then(res => {
      //     if (res[0].status == 1) {
      //       if(res[0].currency==''){
      //         this.currentIndex=''
      //         this.currency=''
      //       }else{
      //         this.currency = res[0].currency;
      //       }

      //     }
      //   });
    },
    //获取k线图数据
    async getKline() {
      this.chartsLoading = true
      const res = await getKline({
        id: this.currentId,
        limit: this.limit,
        type: this.type,
      })
      if (res.status == 1) {
        this.rawData = res.klinePC
        this.initChart()
        const latestData = this.rawData[this.rawData.length - 1]
        // console.log(latestData,421);
        this.currentChartsData = [
          // latestData[0],
          // latestData[1],
          // latestData[2],
          // latestData[5],
          // latestData[4]
          this.rawData[this.rawData.length - 1][0],
          this.rawData[this.rawData.length - 1][1],
          this.rawData[this.rawData.length - 1][2],
          this.rawData[this.rawData.length - 1][5],
          this.rawData[this.rawData.length - 1][4],
        ]
        this.chartsLoading = false

        this.updateData()
      }
    },
    //语言切换
    change() {
      getlanpak(this.value).then((res) => {
        if (res.status == 1) {
          this.$store.commit('Lang/lang', res.language)
          localStorage.setItem('langCut', this.value)
        }
      })
    },

    // 获取买入方式数据
    async getTrade() {
      const res = await getTradeList(this.currentId)
      if (res.status == 1) {
        if (res.gangset != '') {
          this.options3 = res.gangset.map((item) => {
            return {
              label: item.multiple,
              value: item.multiple,
            }
          })
          this.value3 = res.gangset[0].multiple
        } else {
          this.options3 = [{ label: 100, value: 100 }]
          this.value3 = 100
        }

        // console.log(res.gangset[0].multiple,7788);
        // this.value3 = res.gangset[0].multiple;
        this.maxnum = Number(res.currency.max_number)
        this.minnum = Number(res.currency.min_number)
        this.step = Number(res.currency.buchang)
        this.num2 = Number(res.currency.min_number)
        this.sxf1 = Number(res.currency.shouxu).toFixed(3)
        this.meishou_number = Number(res.currency.meizhang_number).toFixed(2)
        this.mode = res.currency.mode
        // if (this.loginStatus) {
        if (this.loginzt&&res.userAccount!=null) {
          this.available_balance = Number(
            res.userAccount.available_balance
          ).toFixed(4)
        }
      }
      this.computedPrice()
    },
    // 获取数据名字
    async getData() {
      // this.fullscreenLoading = true
      const res = await getIndexData()
      if (res.status == 1) {
      this.url = res.sysset.url
        this.name = res.sysset.name
        this.currency = res.pagecontent
          .filter((item) => item.temp == 'usdt')
          .map((item) => item.data.currency)
          console.log(this.currency,321);
        this.currentname = this.currency[0][0].name
        // console.log(this.currency[0],33331313);
        this.ids = this.currency[0].map((currency) => currency.id)
        this.currency_type = res.pagecontent
          .filter((item) => item.temp == 'usdt')
          .map((item) => item.data.currency_type)
        this.options1.unshift({
          label: 'All',
          value: '-1',
        })
        this.options1 = this.options1.concat(
          this.currency_type[0].map((item1, index) => {
            return {
              label: item1.name,
              value: index,
            }
          })
        )
        // this.options1 = this.currency_type[0].map((item1, index) => {
        //   return {
        //     label: item1.name,
        //     value: index
        //   }
        // })
        console.log(this.options1)
        // this.fullscreenLoading = false
        this.currentId = this.currency[0][0].id
        // await this.getPrice(this.ids)
        const formattedData = {};
        this.currency[0].forEach(item => {
          console.log(item,1);
         formattedData[item.id] = {
           name:item.name,
           price: item.price,
           changeRate: item.changeRate
         };
       });
       this.price = formattedData
       console.log(this.price,3);
        this.currentprice = this.price[this.currency[0][0].id].price
        await this.currentData(this.currentId)
        // await this.getKline()
        // await this.currentPrice(this.ids.slice(0, 12))
        const topTwelveIds = this.currency[0]
          .slice(0, 12)
          .map((currency) => currency.id)
        // 筛选出status不等于5的货币对象的id
        const filteredIds = topTwelveIds.filter((id) => {
          const currency = this.currency[0].find((item) => item.id === id)
          return currency.status !== 5
        })
        if (filteredIds != '') {
          // await this.currentPrice(filteredIds);
        }
      }
    },
    // 切换
    changeTopTime(t, d) {
      this.timeflag = t
      this.type = d
      this.getKline()
    },
    // 获取价格
    // async getPrice(ids) {
    //   const batchSize = 15 //每次请求50个id
    //   const numBatches = Math.ceil(ids.length / batchSize) //计算需要分几批请求

    //   for (let i = 0; i < numBatches; i++) {
    //     const start = i * batchSize
    //     const end = (i + 1) * batchSize
    //     const batchIds = ids.slice(start, end) //取出当前批次的ids
    //     const res = await getPrice(batchIds)
    //     if (res.status == 1) {
    //       this.price = { ...this.price, ...res.marketPrice } // 将当前批次的价格合并到this.price中
    //     }
    //   }
    //   console.log(this.price);
    // },
    //获取价格
    async getPrice(ids) {
      // this.fullscreenLoading = true
      const batchSize = 15 // 每次请求15个id
      const numBatches = Math.ceil(ids.length / batchSize) // 计算需要分几批请求
      const promises = []
      let completedRequests = 0 // 完成的请求计数器
      for (let i = 0; i < numBatches; i++) {
        const start = i * batchSize
        const end = (i + 1) * batchSize
        const batchIds = ids.slice(start, end) // 取出当前批次的ids
        promises.push(getPrice(batchIds))
      }
      try {
        const responses = await Promise.all(promises)
        responses.forEach((res) => {
          if (res.status == 1) {
            this.price = { ...this.price, ...res.marketPrice } // 将当前批次的价格合并到this.price中
          }
          completedRequests++ // 每完成一个请求，计数器加1
          if (completedRequests === numBatches) {
            // this.fullscreenLoading = false // 所有请求完成后将loading设置为false
          }
        })
      } catch (error) {
        console.error('Error fetching prices:', error)
      }
      console.log(this.price,476);
    },

    //获取语言分类
    async getLangList() {
      const res = await getlnglist()
      if (res.status == 1) {
        this.options = res.language
        this.langList = res.language
      }
    },
    // 获取订单数据
    // async getOrderList() {
    //   this.loading = true
    //   const res = await getOrderList({
    //     pagenum: this.pagenum,
    //     st: this.active + 1
    //   })
    //   // if (res.status==1) {
    //   this.tableData = res.datalist
    //   this.orderYk = {
    //     margin: res.margin,
    //     yingkui: res.yingkui,
    //     riskRate: res.riskRate
    //     // money:res.userinfo.money
    //   }
    //   this.loading = false
    //   // if (this.loginStatus) {
    //   // if (this.loginzt) {
    //   //   this.emailname = res.userinfo.email;
    //   // }
    //   // }
    // },
    async getOrderList() {
      if (this.loading) {
        return // 避免并发请求
      }

      this.loading = true // 开始加载
      try {
        const res = await getOrderList({
          pagenum: this.pagenum,
          st: this.active + 1, // 使用当前激活的标签状态
        })

        this.tableData = res.datalist
        this.orderYk = {
          margin: res.margin,
          yingkui: res.yingkui,
          riskRate: res.riskRate,
        }
      } catch (error) {
        console.error('Error fetching order list:', error) // 错误处理
      } finally {
        this.loading = false // 结束加载
      }
    },
    handleChange(value) {
      console.log(value)
    },
    // 止盈
    handleChange1(value) {
      console.log(value)
    },
    // 买入数量
    handleChange2(value) {
      console.log(value)
      this.computedPrice()
    },
    // 订单切换
    changeTabs(i) {
      if (this.loading) {
        return // 如果标签已经激活并且没有加载中，则不重复请求
      }
      this.active = i
      this.pagenum = 1
      this.getOrderList()
      console.log(this.active)
    },
    // 币种分类
    change1() {
      this.currentIndex = this.value1
    },
    // 市价
    change2() {
      // this.currentIndex = this.value1;
      console.log(this.value2)
    },
    //倍数
    change3() {
      // this.currentIndex = this.value1;
      console.log(this.value3)
      this.computedPrice()
    },
    // 计算预估保证金
    computedPrice() {
      if (this.mode == 1) {
        this.computedprice = (
          (parseFloat(this.price1) *
            parseFloat(this.num2) *
            parseFloat(this.meishou_number)) /
          this.value3
        )
          // this.multiple

          .toFixed(4)
      } else if (this.mode == 2) {
        this.computedprice = (
          parseFloat(this.num2) * parseFloat(this.meishou_number)
        ).toFixed(4)
      }
      // console.log(
      //   this.price1,
      //   this.num2,
      //   this.meishou_number,
      //   this.computedprice,
      //   666112
      // );
    },
    //滚动刷新价格
    throttledHandleScroll() {
      if (!this.throttleTimeout) {
        this.throttleTimeout = setTimeout(() => {
          this.throttleTimeout = null
          this.handleScroll()
        }, 1000) // 设置时间间隔
      }
    },
    handleScroll() {
      clearTimeout(this.timer)
      const visibleItems = this.getVisibleItems()
      // visibleItems是可见的项目数组，可以根据需要发送请求更新价格
      //请求完重复调用的请求
      if (visibleItems != '') {
        this.currentPrice(visibleItems)
      }
    },
    getVisibleItems() {
      const items = document.querySelectorAll('.left-content .item')
      const visibleItems = []
      items.forEach((item) => {
        if (this.isElementInViewport(item)) {
          const itemId = item.dataset.itemId
          const status = item.dataset.itemStatus
          if (status == 1) {
            visibleItems.push(itemId)
          }
        }
      })
      return visibleItems
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    // 实时刷新价格
    // currentPrice(id) {
    //   getPrice(id).then((res) => {
    //     if (res.status == 1) {
    //       // this.price = res.marketPrice[id].price
    //       // this.price = res.marketPrice
    //       this.price = {
    //         ...this.price,
    //         ...res.marketPrice,
    //       }
    //       // this.currentprice = this.price[this.currency[0][0].id].price;
    //       // currentId
    //       this.currentprice = this.price[this.currentId].price

    //       clearTimeout(this.timer)
    //       this.timer = setTimeout(() => {
    //         this.currentPrice(id)
    //       }, 1000)
    //     }
    //     // console.log(this.price, 763127);
    //   })
    // },
    // async deal(i) {
    //   const data = {
    //     id: this.currentId, //当前选中的id
    //     price_type: this.value2 == 'Marketprice' ? 1 : 2, //1市价还是2挂单
    //     buy_type: i == 1 ? 1 : 2, //1买 2卖
    //     stop_loss: this.zhisunvalue == true ? 1 : 0, //止损1是 0 否
    //     stop_surplus: this.zhiyingvalue == true ? 1 : 0, //止盈1是 0 否
    //     multiple: this.value3, //倍数
    //     stop_loss_price: this.zhisunvalue == true ? this.num : '', //止损价格
    //     stop_surplus_price: this.zhiyingvalue == true ? this.num1 : '', //止盈价格
    //     number: this.num2, //购买数量
    //     hang_price: this.inputprice, //挂单价格
    //     price: this.currentprice //购买价格
    //   }
    //   const res = await getTradeCreate(data)
    //   if (res.status == 1) {
    //     this.getOrderList()
    //     // console.log(222);
    //     this.$notify({
    //       title: this.lang.orderrequestsubmitted || '已提交下单请求',
    //       // message: '以提交下单请求',
    //       type: 'success'
    //     })

    //   } else {
    //     this.$notify({
    //       title: res.msg,
    //       // message: '以提交下单请求',
    //       type: 'error'
    //     })
    //   }
    // },
    // 使用节流包裹deal方法
    deal: _.debounce(async function (i) {
      const data = {
        id: this.currentId,
        price_type: this.value2 == 'Marketprice' ? 1 : 2,
        buy_type: i == 1 ? 1 : 2,
        stop_loss: this.zhisunvalue == true ? 1 : 0,
        stop_surplus: this.zhiyingvalue == true ? 1 : 0,
        multiple: this.value3,
        stop_loss_price: this.zhisunvalue == true ? this.num : '',
        stop_surplus_price: this.zhiyingvalue == true ? this.num1 : '',
        number: this.num2,
        hang_price: this.inputprice,
        price: this.currentprice,
      }

      const res = await getTradeCreate(data)
      if (res.status == 1) {
        this.getOrderList()
        this.$notify({
          title: this.lang.orderrequestsubmitted || '已提交下单请求',
          type: 'success',
        })
      } else {
        this.$notify({
          title: res.msg,
          type: 'error',
        })
      }
    }, 1000),
    //止损开启
    stopLoss() {
      if (this.zhisunvalue) {
        this.num = this.currentprice
      }
      console.log(value, 888)
    },
    //止盈开启
    stopsurplus() {
      if (this.zhiyingvalue) {
        this.num1 = this.currentprice
      }
    },
    // 写一个获取买入卖出的数据价格方法
    // 现在初始化的时候把第一个id穿过来获取数据
    // 然后点击的时候把id传过来切换到这个id的数据
    //还要调用实时刷新价格接口 或者直接根据id来匹配他的价格数据
    async currentData(id) {
      this.currentname = this.currency[0].find((item) => item.id === id)?.name
      this.currentId = id
      await this.getKline()
      await this.getTrade()
      // console.log(this.currentname,666);
      // console.log(this.currency,666);
    },
    opneLogin() {
      this.loginVisible = true
    },
    opneRegister() {
      this.regVisible = true
    },
    // 登录
    async login() {
      if (this.emailAccount == '') {
        this.$notify({
          title: this.lang.enterEmail,
          type: 'warning',
        })
        return
      }
      if (this.password == '') {
        this.$notify({
          title: this.lang.enterpassword,
          type: 'warning',
        })
        return
      }
      const res = await login({
        email: this.emailAccount,
        pwd: this.password,
        logintype: 1,
      })
      if (res.status == 1) {
        await this.$store.dispatch('user/UserToken', res.session_id)
        this.loginzt = 1
        localStorage.setItem('loginzt', 1)
        this.loginVisible = false
        this.getData()
        this.getTrade()
        // this.$store.commit('user/setUserToken', res.session_id)
        this.$store.commit('user/setLoginStatus', 'true')
        this.getOrderList()
        this.getUserInfo()

        this.$notify({
          title: res.msg,
          // message: '以提交下单请求',
          type: 'success',
        })
      } else {
        this.$notify({
          title: res.msg,
          // message: '以提交下单请求',
          type: 'error',
        })
      }
      this.emailAccount = ''
      this.password = ''
    },
    async register() {
      if (this.emailAccount == '') {
        this.$notify({
          title: this.lang.enterEmail,
          type: 'warning',
        })
        return
      }
      if (this.password == '') {
        this.$notify({
          title: this.lang.enterpassword,
          type: 'warning',
        })
        return
      }
      if (this.password.length < 6) {
        this.$notify({
          title: this.lang.passwordcharacters || '新密码不小于6位',
          type: 'warning',
        })
        return
      }
      if (this.pwdAgin == '') {
        this.$notify({
          title: this.lang.enternewpasswordagain || '请再次输入新密码',
          type: 'warning',
        })
        return
      }
      if (this.password != this.pwdAgin) {
        this.$notify({
          title: this.lang.Twodifferentpasswords || '两次密码不一致',
          type: 'warning',
        })
        return
      }
      if (this.yqm == '') {
        this.$notify({
          title: this.lang.plzinput + this.lang.yqm || '请输入邀请码',
          type: 'warning',
        })
        return
      }
      const res = await register({
        email: this.emailAccount,
        pwd: this.password,
        yqcode: this.yqm,
      })
      if (res.status == 1) {
        this.regVisible = false
        this.$notify({
          title: this.RegSuccessful || '注册成功',
          type: 'success',
        })
      } else {
        this.$notify({
          title: res.msg,
          type: 'error',
        })
      }
      this.emailAccount = ''
      this.password = ''
      this.pwdAgin = ''
      this.yqm = ''
    },
    goReg() {
      this.loginVisible = false
      this.regVisible = true
    },
    openOrder(item) {
      console.log(item, '000')
      this.orderid = item.id
      if (item.is_stoploss == 1) {
        this.orderzs = item.stoploss_price
      }
      if (item.is_stopsurplus == 1) {
        this.orderzy = item.stopsurplus_price
      }
      this.orderVisible = true
    },
    handleClose() {
      this.orderzy = ''
      this.orderzs = ''
    },
    // 订单修改
    orderEdit: _.debounce(async function () {
      const res = await orderUpdate({
        id: this.orderid,
        stopsurplus: this.orderzy,
        stoploss: this.orderzs,
      })
      if (res.status == 1) {
        this.$notify({
          title: res.msg,
          type: 'success',
        })
      } else {
        this.$notify({
          title: res.msg,
          type: 'error',
        })
      }
      this.orderVisible = false
      this.orderzy = ''
      this.orderzs = ''
      this.getOrderList()
    }, 1000),
    // async orderEdit() {
    //   const res = await orderUpdate({
    //     id: this.orderid,
    //     stopsurplus: this.orderzy,
    //     stoploss: this.orderzs
    //   })
    //   if (res.status == 1) {
    //     this.$notify({
    //       title: res.msg,
    //       type: 'success'
    //     })
    //   } else {
    //     this.$notify({
    //       title: res.msg,
    //       type: 'error'
    //     })
    //   }
    //   this.orderVisible = false
    //   this.orderzy = ''
    //   this.orderzs = ''
    //   this.getOrderList()
    // },
    // 平仓
    opnepc(id, index) {
      this.pcorco = index
      this.pcVisible = true
      this.orderid = id
    },
    pingCang: _.debounce(async function () {
      const res = await pingCang({ id: this.orderid })
      if (res.status == 1) {
        this.$notify({
          title: res.msg,
          type: 'success',
        })
      } else {
        this.$notify({
          title: res.msg,
          type: 'error',
        })
      }
      this.pcVisible = false
      this.getOrderList()
    }, 1000), // 设置防抖时间为 1000 毫秒

    cancelOrder: _.debounce(async function () {
      const res = await cancelOrder({ id: this.orderid })
      if (res.status == 1) {
        this.$notify({
          title: res.msg,
          type: 'success',
        })
      } else {
        this.$notify({
          title: res.msg,
          type: 'error',
        })
      }
      this.pcVisible = false
      this.getOrderList()
    }, 1000), // 设置防抖时间为 1000 毫秒
    // async pingCang() {
    //   const res = await pingCang({ id: this.orderid })
    //   if (res.status == 1) {
    //     this.$notify({
    //       title: res.msg,
    //       type: 'success'
    //     })
    //   } else {
    //     this.$notify({
    //       title: res.msg,
    //       type: 'error'
    //     })
    //   }
    //   this.pcVisible = false
    //   this.getOrderList()
    // },
    // //撤单
    // async cancelOrder() {
    //   const res = await cancelOrder({ id: this.orderid })
    //   if (res.status == 1) {
    //     this.$notify({
    //       title: res.msg,
    //       type: 'success'
    //     })
    //   } else {
    //     this.$notify({
    //       title: res.msg,
    //       type: 'error'
    //     })
    //   }
    //   this.pcVisible = false
    //   this.getOrderList()
    // },
    initChart() {
      this.chart = echarts.init(document.getElementById('mychart'))
      this.myChart = echarts.init(document.getElementById('mychart'))
      const rawData = this.rawData
      this.dates = rawData.map((item) => item[0])
      this.data = rawData.map((item) => [
        +item[1],
        +item[2],
        +item[5],
        +item[6],
      ])

      this.option = {
        graphic: [
          {
            type: 'group',
            position: 'absolute',
            left: -910,
            top: 10,
            children: [
              {
                type: 'text',
                style: {
                  text:
                    (this.lang.time || '时间') +
                    ': ' +
                    this.currentChartsData[0],
                  fill: '#76808f',
                  font: '12px Arial',
                  textAlign: 'left',
                },
                top: 6,
                left: 0,
              },
              {
                type: 'text',
                style: {
                  text:
                    (this.lang.open || '开') + ': ' + this.currentChartsData[1],
                  fill: '#76808f',
                  font: '12px Arial',
                  textAlign: 'left',
                },
                top: 6,
                left: 80,
              },
              {
                type: 'text',
                style: {
                  text:
                    (this.lang.high || '高') + ': ' + this.currentChartsData[4],
                  fill: '#76808f',
                  font: '12px Arial',
                  textAlign: 'left',
                },
                top: 6,
                left: 160,
              },
              {
                type: 'text',
                style: {
                  text:
                    (this.lang.low || '低') + ': ' + this.currentChartsData[3],
                  fill: '#76808f',
                  font: '12px Arial',
                  textAlign: 'left',
                },
                top: 6,
                left: 235,
              },
              {
                type: 'text',
                style: {
                  text:
                    (this.lang.closed || '收') +
                    ': ' +
                    this.currentChartsData[2],
                  fill: '#76808f',
                  font: '12px Arial',
                  textAlign: 'left',
                },
                top: 6,
                left: 310,
              },
            ],
          },
         
//           {
//   type: 'circle',
//   shape: {
//     cx: 200, // x坐标
//     cy: 200,  // y坐标
//     r: 10,   // 半径
//   },
//   style: {
//     fill: 'red', // 填充颜色
//   },
// }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
            type: 'cross',
            lineStyle: {
              color: '#376df4',
              width: 2,
              opacity: 1,
            },
          },

          formatter: (params) => {
            const item = params[0]
            return (
              this.lang.time +
              ':' +
              item.name +
              '<br/>' +
              this.lang.open +
              '：' +
              item.data[1] +
              '<br/>' +
              this.lang.closed +
              '：' +
              item.data[2] +
              '<br/>' +
              this.lang.low +
              '：' +
              item.data[3] +
              '<br/>' +
              this.lang.high +
              '：' +
              item.data[4]
            )
          },
        },
        xAxis: {
          type: 'category',
          data: this.dates,
          axisLine: { lineStyle: { color: '#8392A5' } },
          splitLine: {
            // show: false,
            show: this.wgshow,
            lineStyle: {
              color: '#E8E8E8',
              type: 'dashed',
            },
          }, //控制网格线显示
        },
        yAxis: {
          scale: true,
          position: 'right',
          axisLine: { show: true, lineStyle: { color: '#8392A5' } },
          splitLine: {
            // show: false,
            show: this.wgshow,
            lineStyle: {
              color: '#E8E8E8',
              type: 'dashed',
            },
          },
          // inverse: true //倒置坐标
          inverse: this.zbshow,
        },
        grid: {
          bottom: 80,
        },
        dataZoom: [
          {
            show: false,
            textStyle: {
              color: '#8392A5',
            },
            dataBackground: {
              areaStyle: {
                color: '#8392A5',
              },
              lineStyle: {
                opacity: 0.8,
                color: '#8392A5',
              },
            },
            brushSelect: true,
          },
          {
            type: 'inside',
          },
        ],
        series: [
          {
            type: 'candlestick',
            name: 'Day',
            data: this.data,
            //控制颜色
            itemStyle: {
              // color: "#0166fc",//涨
              // color0: "#f23c48",//跌
              color: this.rise,
              color0: this.fall,
              // color: "transparent",
              borderColor: '#677c9e',
              borderColor0: '#f23c48',
            },
            markPoint: {
              label: {
                show: true, // 显示标签
                formatter: function (params) {
                  if (params.data.coord) {
                    return params.data.coord[1] // 显示对应的价格值
                  }
                },
                // position: "top" // 标签位置，可以根据需要调整
              },
              data: [
                {
                  type: 'max',
                  name: '最高价',
                  symbol: 'pin',
                  symbolSize: [0, 0],
                  label: {
                    // show: true,
                    show: this.highshow,
                    position: 'top',
                  },
                }, // 最高价
                {
                  type: 'min',
                  name: '最低价',
                  symbol: 'pin',
                  symbolSize: [0, 0],
                  label: {
                    // show: true,
                    show: this.lowshow,
                    position: 'bottom',
                  },
                }, // 最低价
                // {type: 'average', name: '最新价'} // 平均价
              ],
            },
            // 最高价最低价显示
            //     markLine: {
            //       // silent: true, // 整体控制是否响应鼠标事件
            //       // symbol: ["arrow", "arrow"],
            //       data: [
            //         [
            //           {
            //             name: "from lowest to highest",
            //             type: "min",
            //             valueDim: "lowest",
            //             symbol: "arrow",
            //             symbolSize: 10,
            //             label: {
            //               show: false
            //             },
            //             // emphasis: {
            //             //   label: {
            //             //     show: true
            //             //   }
            //             // }
            //           },
            //           {
            //             type: "max",
            //             valueDim: "highest",
            //             // symbol: "circle",
            //             symbolSize: 10,
            //             label: {
            //               show: false,
            //             },
            //             // emphasis: {
            //             //   label: {
            //             //     show: false
            //             //   }
            //             // }
            //           },
            //           {
            //     type: 'average', // 设置为 'average'
            //     symbol: 'none', // 去除标记符号
            //     lineStyle: {
            //         color: 'transparent' // 将线设置为透明
            //     }
            // }
            //         ],
            //         // {
            //         //   name: "min line on close",
            //         //   type: "min",
            //         //   valueDim: "close"
            //         // },
            //         // {
            //         //   name: "max line on close",
            //         //   type: "max",
            //         //   valueDim: "close"
            //         // }
            //       ]
            //     }
          },
        ],
      }

      this.myChart.setOption(this.option)

      this.myChart.on('mousemove', (params) => {
        if (params.seriesType === 'candlestick') {
          this.currentChartsData = [
            params.name,
            params.data[1],
            params.data[2],
            params.data[3],
            params.data[4],
          ]
          this.updateData()
        }
      })
      // this.bindEvents();
    },
    circle(){
      this.circleClick=0
      this.bindEvents();
    },
    bindEvents() {
    this.myChart.getZr().on('click', this.handleFirstClick);
    this.myChart.getZr().on('mousemove', this.handleMouseMove);
  },
  handleFirstClick(params) {
    if (this.circleClick===0) {
      const point = [params.offsetX, params.offsetY];
      this.startPoint = point;
      this.circleClick =1
    }else{
      const radius = Math.sqrt(
      Math.pow(params.offsetX - this.startPoint[0], 2) +
      Math.pow(params.offsetY - this.startPoint[1], 2)
    );
    this.drawCircle(radius);
     // 移除鼠标移动事件监听器，使圆形固定不动
    this.myChart.getZr().off('mousemove', this.handleMouseMove);
    // 清除起始点
    this.startPoint = null;
    this.circleClick=''
    }
  },
  handleMouseMove(params) {
    // 鼠标移动，确定圆的半径
    if (this.startPoint) {
      const currentPoint = [params.offsetX, params.offsetY];
      const radius = Math.sqrt(
        Math.pow(currentPoint[0] - this.startPoint[0], 2) +
        Math.pow(currentPoint[1] - this.startPoint[1], 2)
      );
      this.drawCirclePreview(radius);
    }
  },
  drawCirclePreview(radius) {
  // 绘制圆形预览
  const centerX = this.startPoint[0];
    const centerY = this.startPoint[1];

    // 更新图形属性
    const graphic = {
        type: 'circle',
        id: 'previewCircle', // 设置一个唯一的 ID
        shape: {
            cx: centerX,
            cy: centerY,
            r: radius
        },
        style: {
            stroke: 'red',
            fill: 'rgba(0, 0, 0, 0.1)'
        }
    };

    // 查找并更新现有的预览圆形，如果不存在则添加新的
    let existingGraphicIndex = -1;
    for (let i = 0; i < this.option.graphic.length; i++) {
        if (this.option.graphic[i].id === 'previewCircle') {
            existingGraphicIndex = i;
            break;
        }
    }

    if (existingGraphicIndex !== -1) {
        // 如果已经存在预览圆形，则更新它
        this.option.graphic[existingGraphicIndex] = graphic;
    } else {
        // 如果不存在预览圆形，则添加新的
        this.option.graphic.push(graphic);
    }

    // 更新图表配置
    this.myChart.setOption(this.option);
  },
  drawCircle(radius) {
    // 绘制最终的圆形
    const centerX = this.startPoint[0];
    const centerY = this.startPoint[1];

    // 设置最终圆形的样式
    const graphic = {
      type: 'circle',
      shape: {
        cx: centerX,
        cy: centerY,
        r: radius
      },
      style: {
        stroke: 'red',
        fill: 'rgba(0, 0, 0, 0)'
      }
    };
    this.option.graphic.push(graphic);
    // 更新图表配置
    this.myChart.setOption(this.option);
  },
  rect(){
    this.rectClick=0
      this.bindEvents1();
  },
  bindEvents1() {
    this.myChart.getZr().on('click', this.handleFirstClick1);
    this.myChart.getZr().on('mousemove', this.handleMouseMove1);
  },
  handleFirstClick1(params) {
    if (this.rectClick===0) {
      const point = [params.offsetX, params.offsetY];
      this.startPoint1 = point;
      this.rectClick =1
    }else{
      const point1 = [params.offsetX, params.offsetY];
      this.endPointL1 = point1
    this.drawRect();
     // 移除鼠标移动事件监听器，使圆形固定不动
     this.myChart.getZr().off('mousemove', this.handleMouseMove1);
    this.myChart.getZr().off('click', this.handleFirstClick1);

    // 清除起始点
    this.startPoint1 = null;
    this.rectClick=''
    }
  },
  handleMouseMove1(params) {
    // 鼠标移动，确定圆的半径
    if (this.startPoint1) {
      const currentPoint = [params.offsetX, params.offsetY];
        const width = currentPoint[0] - this.startPoint1[0];
        const height = currentPoint[1] - this.startPoint1[1];
        this.drawRectanglePreview1(this.startPoint1, width, height);
    }
  },
  drawRectanglePreview1(startPoint,w,h) {
  // 绘制圆形预览
  const centerX = startPoint[0];
    const centerY = startPoint[1];

    // 更新图形属性
    const graphic = {
        type: 'rect',
        id: 'previewCircle1', // 设置一个唯一的 ID
        shape: {
            x: centerX,
            y: centerY,
            width: w, // 设置矩形的宽度
        height: h // 设置矩形的高度
            // r: radius
        },
        style: {
            stroke: 'red',
            fill: 'rgba(0, 0, 0, 0.1)'
        }
    };

    // 查找并更新现有的预览圆形，如果不存在则添加新的
    let existingGraphicIndex = -1;
    for (let i = 0; i < this.option.graphic.length; i++) {
        if (this.option.graphic[i].id === 'previewCircle1') {
            existingGraphicIndex = i;
            break;
        }
    }

    if (existingGraphicIndex !== -1) {
        // 如果已经存在预览圆形，则更新它
        this.option.graphic[existingGraphicIndex] = graphic;
    } else {
        // 如果不存在预览圆形，则添加新的
        this.option.graphic.push(graphic);
    }
    
    // 更新图表配置
    this.myChart.setOption(this.option);
  },
  drawRect(){
    const centerX = this.startPoint1[0];
    const centerY = this.startPoint1[1];
    const w = this.endPointL1[0] - this.startPoint1[0];
    const h = this.endPointL1[1] - this.startPoint1[1]
    const graphic = {
        type: 'rect',
        shape: {
            x: centerX,
            y: centerY,
            width: w, // 设置矩形的宽度
        height: h // 设置矩形的高度
            // r: radius
        },
        style: {
            stroke: 'red',
            fill: 'rgba(0, 0, 0, 0.1)'
        }
    };
    this.option.graphic.push(graphic);
    // 更新图表配置
    this.myChart.setOption(this.option);
  },
    updateData() {
      // 模拟数据更新
      // currentChartsData = [/* 更新数据 */];
      this.option.graphic[0].children[0].style.text =
        (this.lang.time || '时间') + ': ' + this.currentChartsData[0]
      this.option.graphic[0].children[1].style.text =
        (this.lang.open || '开') + ': ' + this.currentChartsData[1]
      this.option.graphic[0].children[2].style.text =
        (this.lang.high || '高') + ': ' + this.currentChartsData[4]
      this.option.graphic[0].children[3].style.text =
        (this.lang.low || '低') + ': ' + this.currentChartsData[3]
      this.option.graphic[0].children[4].style.text =
        (this.lang.closed || '收') + ': ' + this.currentChartsData[2]

      // 使用setOption方法更新图表
      this.myChart.setOption(this.option)
    },
    // 放大
    zoomIn() {
      const option = this.myChart.getOption()
      const dataZoom = option.dataZoom[0]

      // if (dataZoom.end - dataZoom.start > 20) {
      // 控制放大的倍数，这里设定为不超过20个数据点
      dataZoom.start += 10 // 自定义放大增加的数据点数量
      dataZoom.end -= 10 // 自定义放大减少的数据点数量

      this.myChart.setOption(option)
      // }
    },
    // 缩小
    zoomOut() {
      const option = this.myChart.getOption()
      const dataZoom = option.dataZoom[0]

      // if (dataZoom.end - dataZoom.start < this.dates.length) {
      // 控制最小缩小到全部数据
      dataZoom.start -= 10 // 自定义缩小减少的数据点数量
      dataZoom.end += 10 // 自定义缩小增加的数据点数量

      this.myChart.setOption(option)
      // }
    },
    // 左右移动
    moveLeft() {
      const option = this.myChart.getOption()
      const dataZoom = option.dataZoom[0]

      // if (dataZoom.start > 0) {
      dataZoom.start -= 5 // 自定义左移的数据点数量
      dataZoom.end -= 5 // 自定义左移的数据点数量

      this.myChart.setOption(option)
      // }
    },
    moveRight() {
      const option = this.myChart.getOption()
      const dataZoom = option.dataZoom[0]

      // if (dataZoom.end < this.dates.length) {
      dataZoom.start += 5 // 自定义右移的数据点数量
      dataZoom.end += 5 // 自定义右移的数据点数量

      this.myChart.setOption(option)
      // }
    },
    // 全屏放大
    handleFullScreen() {
      const element = document.documentElement
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    },
    generateThumbnail() {
      this.imgVisible = true
      const thumbnailDataURL = this.chart.getDataURL({
        pixelRatio: 0.5, // 设置缩略图像素比例，可根据需要调整
        backgroundColor: '#fff', // 设置缩略图背景颜色，可根据需要调整
      })
      this.thumbnailDataURL = thumbnailDataURL
      // console.log(this.thumbnailDataURL, 6666);
    },
    downloadChartImage() {
      const originalDataURL = this.chart.getDataURL({
        pixelRatio: 2, // 设置原始图像素比例，可根据需要调整
        backgroundColor: '#fff', // 设置原始图背景颜色，可根据需要调整
      })

      const link = document.createElement('a')
      link.href = originalDataURL
      link.download = 'chart.png' // 设置下载文件名，可根据需要调整
      link.click()
    },
    ktypechangge() {
      if (this.kvalue == 'solid') {
        this.rise = '#0166fc'
        this.fall = '#f23c48'
      } else if (this.kvalue == 'hollow') {
        this.rise = 'transparent'
        this.fall = 'transparent'
      } else if (this.kvalue == 'Expandedcavity') {
        this.rise = 'transparent'
        this.fall = '#f23c48'
      } else if (this.kvalue == 'fallhollow') {
        this.rise = '#0166fc'
        this.fall = 'transparent'
      }
      this.initChart()
    },
    wgchange(value) {
      this.initChart()
    },
    highchange() {
      // this.highshow = !this.highshow
      this.initChart()
    },
    lowchange() {
      // this.lowchange = !this.lowchange
      this.initChart()
    },
    zbchange() {
      // this.zbshow = !this.zbshow
      this.initChart()
    },
    Restoredefault() {
      this.kvalue = 'solid'
      this.rise = '#0166fc'
      this.fall = '#f23c48'
      this.wgshow = true
      this.highshow = true
      this.lowshow = true
      this.zbshow = false
      this.setVisible = false
      this.initChart()
    },
    handleScroll1() {
      const scrollHeight = document.documentElement.scrollHeight
      const clientHeight = window.innerHeight
      const scrollTop = window.scrollY

      // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.isLoaded) {
      if (scrollTop + clientHeight >= scrollHeight && !this.isLoaded) {
        this.loading = true

        const prevScrollTop = scrollTop
        // 页面已经滚动到底部，此时可以调用接口加载更多数据
        this.isLoaded = true
        this.pagenum += 1
        getOrderList({ pagenum: this.pagenum, st: this.active + 1 }).then(
          (res) => {
            this.tableData = this.tableData.concat(res.datalist)
            this.isLoaded = false
            this.loading = false

            setTimeout(() => {
              window.scrollTo(0, prevScrollTop) // 延迟一段时间后再将页面滚动位置还原
            }, 100) //
          }
        )
        // this.loading = false;
      }
    },
    debounce(func, delay) {
      let timerId

      return function () {
        const context = this
        const args = arguments

        clearTimeout(timerId)

        timerId = setTimeout(() => {
          func.apply(context, args)
        }, delay)
      }
    },
    leftShow() {
      this.leftshow = !this.leftshow
      this.$nextTick(() => {
        this.myChart.resize()
      })
    },
    rightShow() {
      this.rightshow = !this.rightshow
      // setTimeout(() => {
      //   this.myChart.resize(); // 延迟一段时间后再将页面滚动位置还原
      //       }, 100); //这样也行
      this.$nextTick(() => {
        this.myChart.resize()
      })
    },
    // 用户中心
    openUserinfo() {
      this.userinfoVisible = true
      this.btnclick(0)
    },
    inmoney(x) {
      this.rujinflag = x
      if (x == 2) {
        this.$refs.userinfoelm.style.height = '685px'
      } else {
        this.$refs.userinfoelm.style.height = '800px'
      }
    },

    networkchoose(a) {
      var ad = a.split('/')
      console.log(ad)
      this.walletaddresstext = ad[0]
      this.networkid = ad[1]
      console.log(this.walletaddresstext, this.networkid, this.network)
    },
    bankchoose(a) {
      var ad = a.split('/')
      console.log(ad)
      this.bankid = ad[1]
      console.log(this.bankid)
    },
    async copyText(str) {
      console.log(str)
      if (str == '') {
        this.$message({
          message: this.lang.Selectivenetwork || '请选择网络！',
          type: 'error',
        })
        return
      }
      await navigator.clipboard.writeText(str)
      this.$message({
        message: this.lang.Copysuccessful || '复制成功',
        type: 'success',
      })
    },
    async copyyqcode(str) {
      await navigator.clipboard.writeText(str)
      this.$message({
        message: this.lang.Invitationaddresssuccessfully || '邀请地址复制成功',
        type: 'success',
      })
    },
    handleSuccess(response, file) {
      console.log('Upload success:', response.info.url)
      if (this.rujinflag == 1) {
        this.pingzhengimgURL = response.info.url
      } else {
        this.pingzhengimgURL2 = response.info.url
      }
    },
    deleteimg() {
      if (this.rujinflag == 1) {
        this.pingzhengimgURL = null
      } else {
        this.pingzhengimgURL2 = null
      }
    },
    async rujinsubmit() {
      var e = this
      console.log(
        'type:',
        e.rujinflag,
        'payamount:',
        e.chongbinum,
        'payment:',
        e.pingzhengimgURL,
        'itemid:',
        e.networkid
      )
      if (e.rujinflag == 1 ? e.networkid == -1 : e.bankid == -1) {
        this.$message({
          message:
            e.rujinflag == 1
              ? this.lang.Selectivenetwork || '请选择网络！'
              : this.lang.selectcurrency || '请选择货币！',
          type: 'error',
        })
        return
      }
      if (e.rujinflag == 1 ? e.chongbinum == '' : e.banknum == '') {
        this.$message({
          message:
            e.rujinflag == 1
              ? this.lang.enteramountmoney || '请输入充币数量！'
              : this.lang.enterrechargeamount || '请输入充值金额！',
          type: 'error',
        })
        return
      }
      if (
        e.rujinflag == 1 ? e.pingzhengimgURL == '' : e.pingzhengimgURL2 == ''
      ) {
        this.$message({
          message: this.lang.Puploadvoucher || '请上传凭证！',
          type: 'error',
        })
        return
      }
      const res = await chongbiUpdata({
        type: e.rujinflag,
        payamount: e.rujinflag == 1 ? e.chongbinum : e.banknum,
        payment: e.rujinflag == 1 ? e.pingzhengimgURL : e.pingzhengimgURL2,
        itemid: e.rujinflag == 1 ? e.networkid : e.bankid,
      })
      if (res.status == 1) {
        this.$message({
          message: res.msg || '提交成功',
          type: 'success',
        })
        // this.rujinflag =
        e.chongbinum = ''
        e.pingzhengimgURL = ''
        // e.networkid = -1

        e.banknum = ''
        e.pingzhengimgURL2 = ''
        // e.bankid = -1

        this.rujinData()
      }
    },
    async rujinData() {
      const res = await getinmoneyinfo()
      const data = await getinmoneyhistory({
        st: 1,
      })
      this.networkList = res.numusdt
      this.bankList = res.bank
      this.rujinhistory = data.data
      console.log('x == 2', this.bankList, data.data)
    },
    async userindex() {
      const res = await getOrderList({
        pagenum: this.pagenum,
        st: 3,
      })
      return res
    },
    async walletinfo() {
      const res = await getbanklist()
      const data = await getusdtlist()
      return { res, data }
    },
    async pwdsubmit() {
      console.log(this.oldpwd, this.pwd, this.againpwd)
      if (this.oldpwd == '') {
        this.$message({
          message: this.lang.Enteroldpassword || '请输入旧密码！',
          type: 'error',
        })
        return
      }
      if (this.pwd == '' || this.againpwd == '') {
        this.$message({
          message: this.lang.Confirmnewpassword || '请确认新密码！',
          type: 'error',
        })
        this.pwd = ''
        this.againpwd = ''
        return
      }
      if (this.pwd != this.againpwd) {
        this.$message({
          message: this.lang.Twopasswordnotmatch || '两次密码不一致！',
          type: 'error',
        })
        this.pwd = ''
        this.againpwd = ''
        return
      }
      const res = await pwdUpdata({
        oldpwd: String(this.oldpwd),
        pwd: String(this.pwd),
      })
      if (res.status == 1) {
        this.$message({
          message: res.msg || '修改密码成功！',
          type: 'success',
        })
        this.pwd = ''
        this.againpwd = ''
        this.oldpwd = ''
      } else if (res.status == 0) {
        this.$message({
          message: res.msg || '所输入旧密码不正确！',
          type: 'error',
        })
        this.pwd = ''
        this.againpwd = ''
        this.oldpwd = ''
      }
    },
    async deleteUserBorU(id, m) {
      var res
      this.$confirm(
        this.lang.suredelete || '确定要删除吗?',
        this.lang.Promptmessage || '提示',
        {
          confirmButtonText: this.lang.confirm || '确定',
          cancelButtonText: this.lang.cancel || '取消',
          type: 'warning',
        }
      )
        .then(async () => {
          if (m == 1) {
            res = await delbank({
              id: id,
            })
          } else if (m == 2) {
            res = await delusdt({
              id: id,
            })
          }
          if (res.status == 1) {
            this.$message({
              message: res.msg || '删除成功！',
              type: 'success',
            })
          } else {
            this.$message({
              message: res.msg || '删除失败！',
              type: 'error',
            })
          }
          this.btnclick(3)
        })
        .catch(() => {})
    },
    async addbank(m) {
      this.addbankflag = !this.addbankflag
      if (m == 1) {
        this.menuindex = this.lang.Addbankcard || '新增银行卡'
        let res = await getbankHBlist()
        this.bankHBlist = res.list
        this.addflag = 1
      } else {
        this.menuindex = this.lang.Adddigitalcurrencyaddress || '新增数字货币'
        let res = await getusdtHBlist()
        this.usdtHBlist = res.list
        this.addflag = 2
      }
      if (this.addbankflag == false) {
        this.btnclick(3)
      }
    },
    bankHBchoose(a) {
      console.log(a)
      this.bankHBid = a
    },
    usdtHBchoose(a) {
      console.log(a)
      this.usdtHBid = a
    },
    async addsubmit(m) {
      if (m == 1) {
        console.log(
          this.bank_account,
          this.bank_address,
          this.bank_name,
          this.SWIFT,
          this.bank_user,
          this.bankHBid
        )
        if (this.bankHBid == -1) {
          this.$message.error(this.lang.selectcurrency || '请选择货币')
          return
        }
        if (this.bank_name == '') {
          this.$message.error(this.lang.enterbankname || '请输入银行名称')
          return
        }
        if (this.bank_address == '') {
          this.$message.error(this.lang.enterbankaddress || '请输入银行地址')
          return
        }
        if (this.SWIFT == '') {
          this.$message.error(this.lang.enterSWIFT || '请输入SWIFT')
          return
        }
        if (this.bank_user == '') {
          this.$message.error(this.lang.enterpayee || '请输入收款人')
          return
        }
        if (this.bank_account == '') {
          this.$message.error(this.lang.enterpayeeaccount || '请输入收款人账户')
          return
        }
        let res = await addbank({
          bank: {
            bank_account: this.bank_account,
            bank_address: this.bank_address,
            bank_name: this.bank_name,
            bank_swift: this.SWIFT,
            bank_user: this.bank_user,
            proid: this.bankHBid,
          },
        })
        if (res.status == 1) {
          this.$message({
            message: res.msg || '添加成功',
            type: 'success',
          })

          this.bank_account = ''
          this.bank_address = ''
          this.bank_name = ''
          this.SWIFT = ''
          this.bank_user = ''
          this.bankHBid = -1
          this.bankHB = ''

          this.btnclick(3)
          this.addbankflag = false
        }
      } else if (m == 2) {
        console.log(this.usdtHBid, this.usdt_address)
        if (this.usdtHBid == -1) {
          this.$message.error(this.lang.Penteraddress || '请输入地址')
          return
        }
        if (this.usdt_address == '') {
          this.$message.error(this.lang.enterwalletaddress || '请输入钱包地址')
          return
        }
        let res = await addusdt({
          wallet: {
            usdt_address: this.usdt_address,
            proid: this.usdtHBid,
          },
        })
        if (res.status == 1) {
          this.$message.success(res.msg || '添加成功')
          this.usdt_address = ''
          this.usdtHBid = ''

          this.btnclick(3)
          this.addbankflag = false
        }
      }
    },
    async btnclick(x) {
      console.log(x)
      this.$refs.userinfoelm.style.height = '685px'
      if (x == 0) {
        let res = await this.userindex()
        this.userhistory = res.datalist
        this.userinfomoney = res.userinfo
        console.log('cc0:', this.userhistory)
      } else if (x == 1) {
        this.rujinData()
        this.$refs.userinfoelm.style.height = '800px'
      } else if (x == 2) {
        console.log('1:', this.currency)
        this.getWithdrawData()
        this.chujinData()
        console.log('2:', this.currency)
      } else if (x == 3) {
        let res = await this.userindex()
        this.useryue = res.userinfo
        let data = await this.walletinfo()
        this.walletbank = data.res.list.reverse()
        this.walletusdt = data.data.list.reverse()
        console.log(data)
      } else if (x == 4) {
        this.getNameAuth()
      } else if (x == 5) {
        const res = await getyqm()
        this.yqminfo = res
        console.log(res)
      } else if (x == 7) {
        if (this.loginzt == 1) {
          this.$confirm(
            this.lang.areloginout || '确定要退出登录吗?',
            this.lang.Promptmessage || '提示',
            {
              confirmButtonText: this.lang.confirm || '确定',
              cancelButtonText: this.lang.cancel || '取消',
              type: 'warning',
            }
          )
            .then(() => {
              this.loginzt = 0
              this.available_balance = 0
              localStorage.removeItem('loginzt')
              localStorage.setItem('loginStatus', false)
              localStorage.removeItem('session_id')
              this.$store.commit('user/setLoginStatus', false)
              // this.$store.commit('user/setUserToken', 'nmsl')
              // this.$router.push('../index/index.vue')
              this.userinfoVisible = false
              this.getData()
            })
            .catch(() => {})
        } else {
          this.userinfoVisible = false
        }
      }
      this.currentNumber = x
    },
    runmoney(x) {
      this.chujinflag = x
      if (x == 1) {
        this.exchange_rate = this.currencyData[0].exchange_rate
        this.type = this.currencyData[0].type
        this.sxf = this.currencyData[0].carry_rate
        this.currencynum = this.currencyData[0].min_number
      }
      if (x == 2) {
        this.exchange_rate = this.bankData[0].exchange_rate
        this.type = this.bankData[0].type
        this.sxf = this.bankData[0].carry_rate
        this.currencynum = this.bankData[0].min_number
      }
      this.computenum()
    },
    //获取出金数据
    async getWithdrawData() {
      const res = await getWithdraw()
      if (res) {
        this.bankData = res.bank_type
        this.currencyData = res.currency_type
        this.usermoney = res.userinfo.money
        this.currencynum = res.currency_type[this.currencyIndex].min_number
        this.exchange_rate = res.currency_type[this.currencyIndex].exchange_rate
        this.type1 = res.currency_type[this.currencyIndex].type
        this.sxf = res.currency_type[this.currencyIndex].carry_rate
        this.currency1 = res.currency_type[this.currencyIndex].id
        this.bank = res.bank_type[this.bankIndex].id
        this.computenum()
      }
    },
    currencyIndex1(x) {
      this.currencyIndex = this.currencyData.findIndex((item) => item.id === x)
      // console.log(index,21);
      this.currencynum = this.currencyData[this.currencyIndex].min_number
      this.exchange_rate = this.currencyData[this.currencyIndex].exchange_rate
      this.type1 = this.currencyData[this.currencyIndex].type
      this.sxf = this.currencyData[this.currencyIndex].carry_rate
      this.computenum()
    },
    currencyIndex2(x) {
      this.bankIndex = this.bankData.findIndex((item) => item.id === x)
      this.currencynum = this.bankData[this.bankIndex].min_number

      // console.log(index,21);
      this.exchange_rate = this.bankData[this.bankIndex].exchange_rate
      this.type1 = this.bankData[this.bankIndex].type
      this.sxf = this.bankData[this.bankIndex].carry_rate
      this.computenum()
    },
    //计算到账数量
    computenum() {
      console.log(312)
      this.dzCount = this.exchange_rate * this.currencynum
    },
    async submitchujin() {
      const a = {
        type: this.chujinflag, //1数字货币2银行卡
        bankid: this.chujinflag == 1 ? this.tbaddress : this.bankaccount,
        itemid: this.chujinflag == 1 ? this.currency1 : this.bank,
        money: this.currencynum,
        remark: this.remark,
      }
      const res = await subWithdraw(a)
      if (res.status == 1) {
        this.$notify({
          title: res.msg,
          type: 'success',
        })
        this.chujinflag = 1
        this.currency1 = ''
        this.tbaddress = ''
        this.remark = ''
        this.getWithdrawData()
      } else {
        this.$notify({
          title: res.msg,
          type: 'error',
        })
      }
    },
    async chujinData() {
      const res = await getinmoneyhistory({ st: 2 })
      if (res.status == 1) {
        this.chujinhistory = res.data
      }
    },
    // 获取实名认证信息
    async getNameAuth() {
      const res = await getnameAuth()
      this.rzstatus = res.data.status
      this.tips = res.data.marks
      if (res.data.status == 2) {
        getnameAuthInfo(res.data.id).then((res1) => {
          this.smrz = {
            name: res1.data.name,
            zjh: res1.data.card,
            imageUrl: res1.data.card_front,
            imageUrl1: res1.data.card_back,
          }
          console.log(res, 996)
        })
      }
      // -1是还没有认证过 0待审核 2审核失败 1审核成功
    },
    async submitNameAuth() {
      if (this.smrz.imageUrl == '' || this.smrz.imageUrl == '') {
        this.$notify({
          title: this.lang.uploadsf || '请上传照片',
          type: 'error',
        })
      }
      if (this.smrz.name == '') {
        this.$notify({
          title: this.lang.entername || '请输入您的姓名',
          type: 'error',
        })
      }
      if (this.smrz.zjh == '') {
        this.$notify({
          title: this.lang.enterpassportIDnumber || '请输入护照证件号',
          type: 'error',
        })
      }
      const res = await nameAuth(this.smrz)
      if (res.status == 1) {
        this.$notify({
          title: res.msg,
          type: 'success',
        })
        this.smrz = {}
        this.getNameAuth()
      }
    },
    handleAvatarSuccess(res, file) {
      // this.smrz.imageUrl = URL.createObjectURL(file.raw);
      this.smrz.imageUrl = res.info.url

      // console.log(this.smrz.imageUrl,'正');
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg';
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!');
    //   }
    //   return isJPG && isLt2M;
    // },
    handleAvatarSuccess1(res, file) {
      this.smrz.imageUrl1 = res.info.url
      // console.log(this.smrz.imageUrl1,'反');
    },
    // beforeAvatarUpload1(file) {
    //   const isJPG = file.type === 'image/jpeg';
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!');
    //   }
    //   return isJPG && isLt2M;
    // }
  },
}
</script>

<style lang="scss" scoped>
.footer .footer-container .content ::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf,
.el-table--border,
.el-table--group {
  border-bottom: none;
  background-color: #fafbfe;
}

.footer
  .footer-container
  .content
  ::v-deep
  .el-table
  th.el-table__cell.is-leaf {
  border-bottom: none;
  background-color: #fafbfe;
}

.main .left .hd ::v-deep .el-select {
  margin-left: 20px;
  border: none !important;

  .el-input__inner {
    width: 120px;
    padding-left: 26px;
    border: none !important;
    background-color: transparent;
    color: #000;
    font-size: 16px;
  }
}

.main .right .right-bottom ::v-deep .el-input__inner {
  height: 32px;
}

.main .right .right-bottom ::v-deep .el-input-number__decrease {
  top: 4px;
  height: 30px;
}

.main .right .right-bottom ::v-deep .el-input-number__increase {
  top: 4px;
  height: 30px;
}

.order ::v-deep .el-input-number__increase {
  top: 4px;
  height: 30px;
}

.order::v-deep .el-input__inner {
  height: 32px;
}

.order::v-deep .el-input-number__decrease {
  top: 4px;
  height: 30px;
}

.order {
  .orderedit {
    margin-top: 20px;
    background-color: #409eff;
    color: #fff;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
    border-radius: 10px;
  }
}

.pc ::v-deep .el-dialog__body {
  padding: 5px 20px;
  border-radius: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  .hd-left {
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .name {
      height: 38px;
      line-height: 38px;
      margin-right: 10px;
      font-size: 18px;
    }

    .period {
      width: auto;
      padding: 2px 6px;
      margin: 0 4px;
      border-radius: 2px;
      cursor: pointer;
    }

    .selected {
      background-color: #dcebff;
      color: #1677ff;
    }

    .mx-2 {
      height: 38px;
      width: 1px;
      margin: 0 2px;
      background-color: #ebedf1;
    }

    .klinecharts-pro-period-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 38px;
      width: 100%;
      box-sizing: border-box;
    }

    .tools {
      height: 100%;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .icon {
      width: 18px;
      height: 18px;
      margin: 0 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .hd-right {
    display: flex;
    align-items: center;

    .log-reg {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-left: 8px;
      font-size: 14px;
      color: #1a222b;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        // width: 58px;
        height: 32px;
        cursor: pointer;
      }
    }
  }
}

.main {
  // max-width: 1900px;
  height: 100%;
  display: flex;
  // padding: 0 10px;
  position: relative;

  .left::-webkit-scrollbar {
    display: none;
  }

  .left {
    width: 350px;
    min-width: 350px;
    border-radius: 10px;
    box-shadow: 0 0 10px #a1a1a11f;
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    .hd {
      position: sticky;
      top: 0;
      padding: 10px;
      display: flex;
      background-color: #fff;
      z-index: 99;

      .search {
        border-radius: 20px;
        background: #f8f8f8;
        padding: 8px 0;
        height: 40px;
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;

        input {
          background-color: transparent;
          color: #333;
          border: none;
          outline: none;
        }
      }
    }

    .left-content {
      height: 700px;

      .item {
        display: flex;
        align-items: center;
        padding: 14px 10px;
        border: 0 solid #eee;
        border-bottom-width: 1px;

        &:hover {
          background-color: #f3f4f6;
        }

        .item-name {
          display: flex;
          align-items: center;
          flex: 1 1 0% !important;
        }

        .price {
          flex: 1 1 0% !important;
        }

        .changeRate {
          width: 80px;
          padding: 6px 0;
          border-radius: 8px;
          // background-color: red;
          text-align: center;
          font-size: 12px;
          color: white;
        }
      }
    }
  }

  .charts {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    // flex:1;
    padding: 20px;
    height: 750px;
    flex: 1 1 0%;
    margin-left: 10px;

    box-shadow: 0 0 10px #a1a1a11f;
    border-radius: 10px;
    box-sizing: border-box;
    // .echart,#myChart{
    //   width: 100%; height: 100%
    //   div,canvas{
    //   width: 100%; height: 100%

    //   }
    // }
    .charts-hd {
      .current {
        display: flex;
        font-size: 12px;
        color: #76808f;

        .current-item {
          margin-right: 10px;
        }
      }
    }
  }

  .right {
    width: 324px;
    min-width: 324px;
    margin: 0 6px 0 10px;
    box-sizing: border-box;

    .right-top {
      padding: 10px 20px;
      margin-bottom: 18px;
      border-radius: 10px !important;
      background-color: #0166fc;
      text-align: center;
      color: #fff;

      .userId {
        padding: 6px 0;
        font-size: 14px;
      }

      .balance {
        font-size: 14px;
      }

      .money {
        font-size: 28px;
        font-weight: 700;
      }
    }

    .right-bottom {
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px #a1a1a11f;
      box-sizing: border-box;
    }
  }
}

.footer {
  margin: 20px 0;

  .footer-container {
    box-shadow: 0 0 10px #a1a1a11f;
    border-radius: 10px;
    min-height: 300px;

    .container-hd {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tabs {
        display: flex;
        color: #cbcbcb;

        .tabs-item {
          padding: 10px 20px;
          // padding-right: 20px;
          cursor: pointer;
        }

        .selected {
          color: #0166fc;
        }
      }

      .hd-right {
        display: flex;
        align-items: center;
        color: #cbcbcb;

        .bzj {
          margin: 0 30px;
        }
      }
    }

    .content {
      padding: 0 20px;
      font-size: 14px;

      .button {
        padding: 6px 16px;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}

.login {
  display: flex;
  flex-direction: column;

  .email {
    .name {
      font-size: 16px;
      // font-weight: 700;
    }

    input {
      width: 430px;
      height: 40px;
      margin-top: 14px;
      background-color: #f8f8f8;
      border-radius: 20px;
      border: none;
      padding: 0 20px;
      box-sizing: border-box;
      outline: none;
    }
  }

  .pwd {
    margin-top: 28px;

    .name {
      font-size: 14px !important;
      // font-weight: normal !important;
    }
  }

  .button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background-color: #0166fc;
    color: #fff;
  }
}

.set {
  .set-content {
    display: flex;
    flex-direction: column;

    .set-item {
      display: flex;
      margin-bottom: 20px;

      .set-left {
        display: flex;
        align-items: center;
        flex: 1;

        .name {
          width: 100px;
          font-size: 16px;
          font-weight: 500;
          // margin-right: 20px;
        }
      }

      .set-right {
        display: flex;
        align-items: center;
        flex: 1;

        .name {
          width: 100px;
          font-size: 16px;
          // margin-right: 20px;
          font-weight: 500;
        }
      }
    }
  }
}

.userinfo {
  min-height: 745px;
  display: flex;
  // flex-direction: column;

  .email {
    .name {
      font-size: 16px;
      // font-weight: 700;
    }

    input {
      width: 430px;
      height: 40px;
      margin-top: 14px;
      background-color: #f8f8f8;
      border-radius: 20px;
      border: none;
      padding: 0 20px;
      box-sizing: border-box;
      outline: none;
    }
  }

  .pwd {
    margin-top: 16px;

    .name {
      font-size: 14px !important;
      // font-weight: normal !important;
    }
  }

  .button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background-color: #0166fc;
    color: #fff;
  }
}

.el-upload-dragger {
  width: 270px !important;
  height: 150px !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 20px !important;
}
.main .sidebar {
  width: 52px;
  // height: 100%;
  margin-right: 6px;
  box-sizing: border-box;
  border-right: solid 1px #ebedf1;
  background-color: #fff;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-top: 8px;
    cursor: pointer;
    color: #76808f;
    span {
      .img {
        width: 32px;
        height: 32px;
        border-radius: 2px;
        transition: all 0.2s;
        &:hover {
          background-color: #dcebff;
        }
      }
      &:hover .arrow {
        // background-color: red;
        display: block;
      }
      .arrow {
        //   display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        height: 32px;
        width: 10px;
        // opacity: 0;
        transition: all 0.2s;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        z-index: 10;
        .img-responsive {
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
        &:hover {
          background-color: #dcebff;
        }
      }
    }
    &:hover {
      ul {
        display: block;
      }
    }
  }
}
.main .sidebar .item ul {
  display: none;
  width: 100px;
  max-height: 320px;
  padding-left: 0;
  position: absolute;
  right: -100px;
  top: -20px;
  z-index: 999;
  list-style: none;
  background-color: #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
  }
  li {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    &:hover {
      background-color: #dcebff;
    }
  }
}
::v-deep .el-table thead {
  font-size: 12px;
  color: #cbd5e2;
}
.main {
  padding: 0;
}
</style>
