import Vue from 'vue'
import Vuex from 'vuex'
// 导入语言包vuex模块
import Lang from '@/store/lang.js'
import user from '@/store/user.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Lang:Lang,
    user
    // 2. 挂载的 vuex 模块，模块内成员的访问路径被调整为 m_cart，例如：
       //    模块中 cart 数组的访问路径是 m_cart/cart
       // m_cart: moduleCart,
       // 挂载用户的 vuex 模块，访问路径为 m_user
           // m_user: moduleUser,
  }
})
