<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getlnglist, getlanpak } from "@/api/index";
export default {
  name: "index",
  data() {
    return {};
  },
  created() {
    this.getInitLang();
  },
  methods: {
    // 设置系统默认语言
    getInitLang() {
      const config = localStorage.getItem("langCut");
      if (config) {
        getlanpak(config).then(res => {
          if (res.status == 1) {
            this.$store.commit('Lang/lang', res.language)
          }
        });
      } else {
        getlnglist().then(res => {
          if (res.status == 1) {
            const id = res.language.find(d => d.default == "1").id;
            localStorage.setItem("langCut", id);
            getlanpak(id).then(res1 => {
              if (res1.status == 1) {
                this.$store.commit("Lang/lang", res1.language);
              }
            });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
