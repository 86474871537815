import request from '@/utils/request'

// export const getArticleList = data => {
//   return request.get('/admin/interview/query', {
//     params: data
//   })
// }
// export const getArticleList = data => {
//   // return request.post('/ApiIndex/loginsub&aid=1&platform=h5&session_id=c15fffa335ee8e95caa7afbef60e68a5&pid=0', data)

//   return request.post('/ApiIndex/loginsub', data)
// }

// 获取语言列表
export const getlnglist = () => {
  return request.get('/ApiLanguage/lnglist')
}

//获取语言
export const getlanpak = data => {
  // return request.get('/ApiLanguage/lanpak&needinit=1',{lid:data})
  return request.get(`/ApiLanguage/lanpak&lid=${data}`)
}

// 获取首页数据
export const getIndexData = () => {
  return request.get('/ApiIndex/index')
}

// 获取数据价格
export const getPrice = data => {
  return request.get(`/ApiTrade/getMarketPrice&ids=${data}`)
}

//获取订单数据
export const getOrderList = data => {
  return request.get(
    `/ApiOrder/orderlist&pagenum=${data.pagenum}&st=${data.st}`
  )
}

//交易详情数据
export const getTradeList = data => {
  return request.get(`/ApiTrade/detail&id=${data}`)
}

//买卖
export const getTradeCreate = data => {
  return request.post(`/ApiTrade/create`, data)
}

//登录
export const login = data => {
  return request.post(`ApiIndex/loginsub`, data)
}

// 注册
export const register = data => {
  return request.post(`ApiIndex/regsub`, data)
}

//订单修改 TP/SL
export const orderUpdate = data => {
  return request.post(`ApiOrder/orderupdate`, data)
}

//平仓
export const pingCang = data => {
  return request.post(`ApiOrder/pingcang`, data)
}

//撤单
export const cancelOrder = data => {
  return request.post(`ApiOrder/cancelorder`, data)
}

//股票k图
export const getKline = data => {
  return request.get(
    `ApiTrade/kline&id=${data.id}&limit=${data.limit}&type=${data.type}`
  )
}

//搜索
export const search = data => {
  return request.get(`ApiShop/list&name=${data}`)
}

//入金
export const getinmoneyinfo = () => {
  return request.get(`/ApiMoney/recharge`)
}
//入金记录
export const getinmoneyhistory = data => {
  return request.get(`ApiMy/moneylog&st=${data.st}`)
}
//提交充币申请
export const chongbiUpdata = data => {
  return request.post(`/ApiMoney/recharge`, data)
}

//获取邀请码信息
export const getyqm = () => {
  return request.get(`ApiAgent/poster`)
}

//修改密码
export const pwdUpdata = data => {
  return request.post(`ApiMy/setpwd`, data)
}

//获取绑定银行卡列表
export const getbanklist = () => {
  return request.get(`ApiBank/banklist`)
}

//获取绑定货币列表
export const getusdtlist = () => {
  return request.get(`ApiCurrency/list`)
}

//删除设定银行
export const delbank = data => {
  return request.get(`ApiBank/delbank&id=${data.id}`)
}

//删除设定提币
export const delusdt = data => {
  return request.get(`ApiCurrency/del&id=${data.id}`)
}

//获取银行卡货币列表
export const getbankHBlist = () => {
  return request.get(`ApiBank/legal`)
}

//提交新增银行卡信息
export const addbank = data => {
  return request.post(`ApiBank/bank&pid=0`, data)
}

//获取USDT货币列表
export const getusdtHBlist = () => {
  return request.get(`ApiCurrency/digital`)
}

//提交新增USDT信息
export const addusdt = data => {
  return request.post(`ApiCurrency/wallet`, data)
}


//获取出金数据
export const getWithdraw = ()=>{
  return request.get(`ApiMy/withdraw`)
}

//出金提交
export const subWithdraw = (data)=>{
  return request.post(`ApiMy/withdraw`,data)
}

// 获取实名认知数据
export const getnameAuth =  ()=>{
  // return request.get(`/ApiMy/nameauth`)
  return request.get(`/ApiMy/nameauthmsg`)
}

//实名认证
export const nameAuth =  (data)=>{
  return request.post(`/ApiMy/nameauth`,{auth:{
    name:data.name,
    card:data.zjh,
    card_front:data.imageUrl,
    card_back:data.imageUrl1
  }})
}


export const getnameAuthInfo =  (data)=>{
  return request.get(`/ApiMy/nameauth&id=${data}`)
}

//获取用户信息
export const userinfo =  ()=>{
  return request.get(`ApiMy/usercenter`)
}

//订单价格刷新

export const getOrderlistPrice =  (data)=>{
  return request.get(`ApiOrder/orderlistPrice&ids=${data.ids}&st=${data.st}`)
}

export const getPriceData =  ()=>{
  return request.get(`/ApiTrade/quotes`)
}