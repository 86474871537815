/* 封装axios用于发送请求 */
import axios from 'axios'

import { getToken, setToken } from '@/utils/storage'

// 创建一个新的axios实例
const request = axios.create({
  // baseURL: 'https://usdt.yanshi001.cc/?s=',
  baseURL: 'https://api.dimiantong.com/?s=',

  timeout: 60000
})

const token = getToken()
// // 添加请求拦截器
// request.interceptors.request.use(function (config) {
//   // 在发送请求之前做些什么
//   return config
// }, function (error) {
//   // 对请求错误做些什么
//   return Promise.reject(error)
// })

// // 添加响应拦截器
// request.interceptors.response.use(function (response) {
//   // 对响应数据做点什么
//   return response.data
// }, function (error) {
//   // 对响应错误做点什么
//   return Promise.reject(error)
// })

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  const newConfig = { ...config }  // 使用解构赋值创建新对象，避免直接修改原始配置
  newConfig.params = newConfig.params || {}  // 确保有params对象
  newConfig.params.aid = 1  // 添加aid参数
  newConfig.params.platform = 'h5'  // 添加aid参数
  // if(token != null){
  //   newConfig.params.session_id = token
  // }
  const currentToken = getToken() // 获取最新的token
  if (currentToken) {
    newConfig.params.session_id = currentToken
  }
  return newConfig
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // if(token==null){
  //   if (response && response.data && response.data.mid == 0) {
  //     setToken(response.data.session_id)
  //   }else if(response && response.data && response.data._initdata && response.data._initdata.mid == 0){
  //     setToken(response.data._initdata.session_id)
  //   }
  // }
  if (!getToken()) {
    if (response && response.data && response.data.mid == 0) {
      setToken(response.data.session_id)
      // 发送空请求以触发请求拦截器逻辑
      // request.get('/some-empty-endpoint').then(() => {
      //   console.log('Token updated and request interceptor logic triggered.')
      // })
    } else if (response && response.data && response.data._initdata && response.data._initdata.mid == 0) {
      setToken(response.data._initdata.session_id)
      // 发送空请求以触发请求拦截器逻辑
      // request.get('/some-empty-endpoint').then(() => {
      //   console.log('Token updated and request interceptor logic triggered.')
      // })
    }
  }
  return response.data
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})


export default request