export default {
  // 为当前模块开启命名空间
  namespaced: true,

  // 模块的 state 数据
  state: () => ({
  lang: [],
  }),

  // 模块的 mutations 方法
  mutations: {
 lang(state, payload) {
 	state.lang = payload
 }
  },
  actions:{},
  // 模块的 getters 属性
  getters: {
  
},
}